import React, { useEffect, useState, useMemo } from "react";
import Form from "react-bootstrap/Form";
import he from "he";
import { Input } from "rsuite"; // Import the HTML entity decoder library

export default function FormulaBuilderPreprationInstructionsSection({
  formulaBuilderId,
  data,
  ingrediuentsTags = [],
  ingredients,
  onChange,
  formulaDeatils,
  display_name,
  thisFormData,
}) {
  const [herbTags, setHerbTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [selected, setSelected] = useState([]);
  const finalTagArr = ingredients.map(
    (v) => v["formula_dosage_column_herb_add"]
  );
  const [decodedText, setDecodedText] = useState("");

  const [formValue, setFormValue] = React.useState({});
  var requirements = [
    {
      column: "preparation_instructions",
      name: "Preparation Instructions",
      is_required: 0,
      key: "preparation_instructions",
      allow_zero: false,
    },
  ];

  const filteredArray = finalTagArr.filter((item, index) => {
    if (item == "") {
      return false;
    }
    return index == finalTagArr.indexOf(item);
  });
  useEffect(() => {
    onChange(herbTags);
    if (herbTags.length != 0) {
      formValidation();
    }
  }, [herbTags]);
  
  if (filteredArray && filteredArray.length != 0) {
    ingrediuentsTags.forEach((value, key) => {
      if (
        filteredArray.includes(value.id) &&
        herbTags.every((row) => row.tag_id !== value.id) &&
        value.tags_data.length > 0
      ) {
        herbTags.push({
          rowNo: herbTags.length + 1,
          tag_id: value.id,
          tag_name: value.name,
          tag_preparation: value.allow_preparation_instructions,
          tag_options: value.tags_data,
          tag_selected: "",
          tag_selected_name: "",
          variableText: "",
          variableArr: [],
          text: "",
        });
      }
    });

    herbTags.map((value, key) => {
      if (!filteredArray.includes(value.tag_id)) {
        const updatedTags = herbTags.filter(
          (data) => data.tag_id !== value.tag_id
        );
        setHerbTags(updatedTags);
      }
    });
  }

  useEffect(() => {
    if (
      formulaDeatils &&
      formulaDeatils.id &&
      herbTags.length != 0 &&
      selectedTag.length == 0
    ) {
      var preparation_instructions = formulaDeatils.preparation_instructions
        ? JSON.parse(formulaDeatils.preparation_instructions)
        : [];

      if (preparation_instructions.length != 0) {
        preparation_instructions.map((value, key) => {
          var thisSelectedTag = value.tag_selected;
          if (thisSelectedTag) {
            var array = herbTags.find((item) => item.tag_id == value.tag_id);
            if (array) {
              selectedTag.push(parseInt(value.tag_selected));
              array.tag_selected = thisSelectedTag;
              array.variableText = array.text;
              HandelTags(value.rowNo, thisSelectedTag, array);
              var selectedVaribales = value.variableArr;
              var selectedPrewiew = value.text;
              if (selectedVaribales && selectedVaribales.length != 0) {
                setTimeout(() => {
                  selectedVaribales.map((option, index) => {
                    HandelTagVariables(
                      array.rowNo,
                      option.value,
                      option.field,
                      array
                    );
                  });
                  setTimeout(() => {
                    HandelPreviewText(array.rowNo, value, selectedPrewiew);
                  }, 500);
                }, 500);
              }
            }
            // } else if(value.text!='' && value.text!=undefined && value.tag_selected==''){
          } else {
            var selectedPrewiew = value.text;
            setTimeout(() => {
              // console.log(
              //   value.rowNo,
              //   value,
              //   selectedPrewiew,
              //   "value.rowNo, value, selectedPrewiew"
              // );
              // HandelPreviewText(value.rowNo, value, selectedPrewiew);
            }, 500);
          }
        });
      }
    }
  }, [formulaBuilderId, herbTags, formulaDeatils, filteredArray]);

  // no herb tag selected

  const HandelTags = (rowNo, value, array) => {
    var tagsData = array.tag_options;
    var selectedRow = tagsData.find((obj) => obj.id == value);
    if (selectedRow) {
      // find text variables
      var text = selectedRow.text;
      var pattern = /\[(.*?)\]/g;
      var matches = text.match(pattern);
      var variableArr = [];
      if (matches && matches.length > 0) {
        matches.map((v, k) => {
          var variableName = v.trim();
          variableName = variableName.replace(/\[|\]/g, "");
          variableName = variableName.trim();
          var variable = variableName.toLowerCase();
          variable = variable.replace(/\s+/g, "_");
          const field = {
            field: variable,
            name: variableName,
            replace: v.trim(),
            value: "",
          };
          variableArr.push(field);
        });
      }
      // update value
      array.tag_selected = value;
      array.tag_selected_name = selectedRow.title;
      array.variableText = selectedRow.text;
      array.variableArr = variableArr;
      array.text = selectedRow.text;
      //update Herb tags
      setHerbTags(herbTags.map((val) => (val.rowNo == rowNo ? array : val)));
    } else {
      array.tag_selected = "";
      array.tag_selected_name = "";
      array.variableText = "";
      array.variableArr = [];
      array.text = "";
      //update Herb tags
      setHerbTags(herbTags.map((val) => (val.rowNo == rowNo ? array : val)));
    }
  };

  const HandelTagVariables = (rowNo, val, field, array) => {
    var finalText = array.variableText;
    var variableArr = array.variableArr;
    variableArr.map((v, k) => {
      if (v.field == field) {
        v.value = val;
      }
      if (v.value) {
        var replaceField = v.replace;
        finalText = finalText.replace(replaceField, v.value);
      }
    });
    array.variableArr = variableArr;
    array.text = finalText;

    setHerbTags(herbTags.map((val) => (val.rowNo == rowNo ? array : val)));
  };

  const HandelPreviewText = (rowNo, array, value) => {
    var arrayt = herbTags.find((item) => item.rowNo == rowNo);
    if (arrayt) {
      arrayt.text = decodeEntities(value);
      setHerbTags(herbTags.map((val) => (val.rowNo == rowNo ? arrayt : val)));
    }
  };

  const formValidation = () => {
    formValue["preparation_instructions"] = herbTags;
    var Final_array = [];
    Object.values(requirements).forEach((field) => {
      const ikey = field.key;
      const temp = {
        ...field,
        error: "",
        value: formValue[ikey],
      };
      Final_array.push(temp);
    });

    thisFormData(Final_array, "FORMULAS_PREPARATION_INSTRUCTIONS");
  };

  if (filteredArray.length == 0) {
    return <></>;
  }

  const decodeEntities = (html) => {
    const doc = new DOMParser().parseFromString(he.decode(html), "text/html");
    // setDecodedText(doc.documentElement.textContent);
    return doc.documentElement.textContent;
  };

  return (
    <>
      {
        <div className="FormulaBuilderPreprationInstructionsSection">
          <h2 className="title">
            {!data.blank_preparation_instructions && display_name}
          </h2>
          <div className="row">
            <div className="col-8">
              {herbTags.map((value, key) => {
                var tagsData = value.tag_options;
                const idArray = tagsData.map((item) => item.id);
                const selectedIds = "";
                if (selectedTag.length != 0 && value.tag_selected == "") {
                  const selectedIds = selectedTag.filter((id) =>
                    idArray.includes(id)
                  );
                  tagsData.map((val, k) => {
                    if (val.id == selectedIds) {
                      HandelTags(value.rowNo, selectedIds, value);
                    }
                  });
                } else if (value.tag_selected != "") {
                }

                if (value.tag_preparation == 0) {
                  return;
                } else {
                  return (
                    <>
                      <div className="row">
                        <h5 className="instructionsTitle mt-4">
                          {value.tag_name} Instructions
                        </h5>
                        <br></br>

                        <div
                          className="row"
                          style={{
                            marginTop: "1rem",
                            padding: "0px",
                            marginLeft: "1rem",
                          }}
                        >
                          <Form.Group
                            className="col-3"
                            style={{ paddingLeft: "0px" }}
                          >
                            <Form.Select
                              style={{
                                padding: ".3rem 2.25rem .3rem .75rem",
                                border: "1px solid black",
                              }}
                              onChange={(e) =>
                                HandelTags(value.rowNo, e.target.value, value)
                              }
                            >
                              <option value="" placeholder="Tag Insert">
                                Select..
                              </option>
                              {tagsData.map((val, k) => {
                                return (
                                  <option
                                    key={val?.id}
                                    data-text={val?.text}
                                    value={val?.id}
                                    selected={value?.tag_selected == val?.id}
                                  >
                                    {val?.title}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>

                          {value.tag_selected != null &&
                          value.tag_selected != 0 &&
                          value.tag_selected != "" ? (
                            <>
                              {value.variableArr.map((v, k1) => {
                                return (
                                  <>
                                    <div
                                      className="col"
                                      style={{ maxWidth: "200px" }}
                                      title={v.name}
                                    >
                                      <input
                                        type="text"
                                        value={v.value}
                                        placeholder={v.name}
                                        title={v.name}
                                        onChange={(e) =>
                                          HandelTagVariables(
                                            value.rowNo,
                                            e.target.value,
                                            v.field,
                                            value
                                          )
                                        }
                                        className="form-control"
                                      />
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : null}
                          <br></br>
                          <Input
                            as="textarea"
                            rows={6}
                            value={decodeEntities(value.text)}
                            // value={
                            //   decodedText == ""
                            //     ? decodeEntities(value.text)
                            //     : decodedText
                            // }
                            className="col-12"
                            onChange={(e) => {
                              console.log(e, "onChange----e");
                              HandelPreviewText(value.rowNo, value, e);
                            }}
                            style={{
                              maxHeight: "15rem",
                              marginTop: "0.8rem",
                              border: "1px solid black",
                            }}
                            contentEditable={true}
                          />
                        </div>
                      </div>
                    </>
                  );
                }
              })}
            </div>
            <div className="col-4"></div>
          </div>
        </div>
      }
    </>
  );
}
