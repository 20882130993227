import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./FormulaBuilder.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Loader, Placeholder, Notification, useToaster } from "rsuite";
import he from "he"; // Import the HTML entity decoder library
import { format } from "date-fns";
import { Icon } from "@rsuite/icons";
import { FaExclamationTriangle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  ApiFormulaBuilderDetails,
  ApiFormulaBuilderDosagesDetails,
  ApiFormulaBuilderList,
  ApiVerifyPrac,
  ApiFormulaBulderIngredientLists,
  ApiFormulaDetails,
  ApiSaveFormulaDetails,
  ApiFormulaExtraData,
} from "../../services/api/api_formula_builder";
import FormulaBuilderGeneralSection from "./FormulaBuilderGeneralSection/FormulaBuilderGeneralSection";
import FormulaBuilderOptionsSection from "./FormulaBuilderOptionsSection/FormulaBuilderOptionsSection";
import FormulaBuilderDosagesSection from "./FormulaBuilderDosagesSection/FormulaBuilderDosagesSection";
import { FormulaBuilderIngredientsSection } from "./FormulaBuilderIngredientsSection/FormulaBuilderIngredientsSection";
import FormulaBuilderPreprationInstructionsSection from "./FormulaBuilderPreprationInstructionsSection/FormulaBuilderPreprationInstructionsSection";
import { FormulaBuilderWithoutTagPreprationInstructionsSection } from "./FormulaBuilderWithoutTagPreprationInstructionsSection/FormulaBuilderWithoutTagPreprationInstructionsSection";
import FormulaBuilderServicesSection from "./FormulaBuilderServicesSection/FormulaBuilderServicesSection";
import FormulaBuilderMarkupSection from "./FormulaBuilderMarkupSection/FormulaBuilderMarkupSection";
import FormulaBuilderPriceSection from "./FormulaBuilderPriceSection/FormulaBuilderPriceSection";
import FormulaBuilderSummarySection from "./FormulaBuilderSummarySection/FormulaBuilderSummarySection";
import FormulaBuilderRefillSection from "./FormulaBuilderRefillSection/FormulaBuilderRefillSection";
import FormulaBuilderDispensaryInstructions from "./FormulaBuilderDispensaryInstructionSection/FormulaBuilderDispensaryInstructionSection";
import { FormulaSaveButton } from "./helpers/FormulaSaveButton";
import IngredientPopup from "../FormulaBuilder/helpers/outOfStockPopup";
import BundlePopup from "../FormulaBuilder/helpers/bundlePopup";
import FormulaSectionTextBox from "./helpers/TextBox";
import { APIConfig } from "../../apiConfig";
import {
  getLabelSettings,
  getInputSettings,
  getMarginSettings,
  getBorderSettings,
  getDropdownStyles,
  getbgStyle,
  getPaddingSettings,getTabelSettings,getTableMarginSetting,getTableHeaderSetting
} from "./helpers/LabelFormat";
const { BASEURL } = APIConfig;

const decodeEntities = (html) => {
  const doc = new DOMParser().parseFromString(he.decode(html), "text/html");
  return doc.documentElement.textContent;
};

const FormulaBuilder = () => {
  const { id, prac, formula = "", is_order = false } = useParams();
  console.log("URLparams", { id, prac, formula, is_order });
  // formula detials
  const [formulaId, setformulaId] = useState(formula);
  const [formulaDeatils, setformulaDeatils] = useState([]);
  const { summaryReducer } = useSelector((response) => response);
  const [formulaSummaryData, setSummaryData] = useState("");
  const [formulaOptionCheck, setFormulaOption] = useState("");
  const [formulaTotalQty, setFormulaTotalQty] = useState("");
  const [formulaDosagesSetting, setformulaDosagesSetting] = useState([]);
  const [AllowEnterQty, setAllowEnterQty] = useState(false);
  const [AllowRawQty, setAllowRawQty] = useState(false);
  // Callback function to receive data from the child
  const handleSummaryData = (summarydata, allow_enter, allow_raw) => {
    setSummaryData(summarydata);
    setAllowEnterQty(allow_enter);
    setAllowRawQty(allow_raw);
  };

  const [formulaBuilderId, setformulaBuilderId] = useState(id);
  const [pracId, setPracId] = useState(prac);
  const [validPrac, setValidPrac] = useState(false);
  const [pracDetails, setPracDetails] = useState([]);
  const [prevFormulaBuilderId, setprevFormulaBuilderId] = useState("");
  const [curentformulaBuilderId, setCurrentformulaBuilderId] = useState("");
  const [allIngredientsResponse, setAllIngredientsResponse] = useState([]);
  const navigate = useNavigate();
  const toaster = useToaster();
  const [formulaError, setformulaError] = useState(false);
  const [formulaErrorMsg, setformulaErrorMsg] = useState(null);
  const [concentrates, setConcentrates] = useState([]);
  const [autoSaveAfter, setAutoSaveAfter] = useState(10000); // 60000 milliseconds = 60 seconds
  const [formulaFormData, setformulaFormData] = useState({}); // All section data with validation error
  const [data, setData] = useState(null);
  const [formulaList, setformulaList] = useState([]); // get active formulaList according to Admin && Prac Setting
  const [prevdata, setPrevData] = useState(null);
  const [prevFormulaBuilderType, setprevFormulaBuilderType] = useState("");
  const [prevFormulaBuilderIngredients, setprevFormulaBuilderIngredients] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const [currency, setCurrency] = useState("$");
  const [nameTypeArr, setNameTypeArr] = useState([]);
  const [measurmentConversions, setMeasurmentConversions] = useState([]);
  const [seletedIngredientType, setSeletedIngredientType] = useState([]);

  // section wise data
  const [formulaSections, setformulaSections] = useState([]);
  //gernal section input data consts
  const [formulaBuilderType, setformulaBuilderType] =
    useState(formulaBuilderId);
  const [formulaBuilderName, setformulaBuilderName] = useState(null);
  const [formulaBuilderDecoction, setformulaBuilderDecoction] = useState(0);
  const [formulaBuilderPatient, setformulaBuilderPatient] = useState(0);
  const [formulaBuilderDosageMode, setformulaBuilderDosageMode] = useState(0);
  const [formulaBuilderDosageModeType, setformulaBuilderDosageModeType] =
    useState(null);
  const [formulaBuilderDosageModeKey, setformulaBuilderDosageModeKey] =
    useState(null);
  const [formulaBuilderShareFormula, setformulaBuilderShareFormula] =
    useState(0);
  const [formulaBuilderPractice, setformulaBuilderPractice] = useState(0);
  const [formulaBuilderSupervisor, setformulaBuilderSupervisor] = useState(0);
  const [formulaBuilderTemplate, setformulaBuilderTemplate] = useState(0);
  // option section input data consts
  const [defaultMeasuremt, setdefaultMeasuremt] = useState(0);
  const [defaultMeasuremtName, setdefaultMeasuremtName] = useState("");
  const [allow_multiple_measurements, setallow_multiple_measurements] =
    useState(false);
  const [measurementOptions, setmeasurementOptions] = useState([]);
  const [ingredient_selection, setingredient_selection] = useState([]);
  const [totalQty, setTotalQty] = useState(0);
  const [activeDosages, setActiveDosages] = useState(""); // if capsule/tablets/softgel active then get Dosage Inst. according to this
  const [activeOptionDosagesData, setActiveOptionDosagesData] = useState([]);
  const [isCapsuleSelected, setIsCapsuleSelected] = useState(0);
  const [capsuleSizeSelected, setCapsuleSizeSelected] = useState(0);
  const [capsuleQty, setCapsuleQty] = useState(0);
  const [numberBags, setNumberBags] = useState(0);
  const [numberDays, setNumberDays] = useState(0);

  const [minBasedQty, setMinBasedQty] = useState(0);
  const [maxBasedQty, setMaxBasedQty] = useState(0);
  const [showOutOfStockPopup, setOutOfStockPopup] = useState(false);
  const [outOfStockIngredients, setOutOfStockIngredients] = useState([]);
  const [showBundlePopup, setBundlePopup] = useState(false);
  const [bundleErrMsg, setBundleErrMsg] = useState("");

  // Price section input data consts
  const [priceArr, setPriceArr] = useState([]);
  const [price, setPrice] = useState(0);
  // Markup section input data consts
  const [allowMarkup, setallowMarkup] = useState(0);
  const [markup_type, setmarkup_type] = useState(""); // flat,percentage
  const [markup_amount, setmarkup_amount] = useState(0);
  // Dosages Section
  const [dosageSettings, setdosageSettings] = useState([]);
  const [usePresetDosageInstructions, setUsePresetDosageInstructions] =
    useState(false);
  const [dosagesData, setdosagesData] = useState([]);
  const [dosagesText, setdosagesText] = useState("");
  const [allowOwnDosagesInstructions, setAllowOwnDosagesInstructions] =
    useState(true);
  const [ownDosagesInstructions, setOwnDosagesInstructions] = useState("");
  const [DosagesTotalQty,setDosagesTotalQty] =useState(0)
  const [automaticallyCalculateDosages, setAutomaticallyCalculateDosages] =
    useState(false);
  const [
    allowUserToChangeAutomaticallyCalculateDosages,
    setAllowUserToChangeAutomaticallyCalculateDosages,
  ] = useState(false);

  // ingredient Section
  const [formulaIngredients, setformulaIngredients] = useState([]);
  const [formulaIngredientsIdArr, setformulaIngredientsIdArr] = useState([]);
  const [nameTypes, setNameTypes] = useState([]);
  const [ingredientNamePostFix, setingredientNamePostFix] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [ingrediuentsTags, setIngrediuentsTags] = useState([]);

  const [seperatorText, setSeperatorText] = useState("");
  const [seperator, setSeperatorSign] = useState(" ");
  const [ingredient_decimal, setingredient_decimal] = useState([]);
  // Preparation Instructions
  const [herbTags, setHerbTags] = useState([]);
  const [blankHerbTags, setBlankHerbTags] = useState([]);
  // Addon Servies
  const [addOnServices, setaddOnServices] = useState([]);
  const [selectedAddonServices, setSelectedAddonServices] = useState([]);
  //Price
  const [totalPrice, setTotalPrice] = useState(0);
  const [ingredientTotal, setIngredientsTotal] = useState(0);
  const [markupTotal, setMarkupTotal] = useState(0);
  const [addOnServiesTotal, setAddOnServiesTotal] = useState(0);
  const [subtotalPrice, setSubTotalPrice] = useState(0);
  const [feesTotal, setFeesTotal] = useState(0);
  const [capsulefeesTotal, setcapsulefeesTotal] = useState(0);

  // Refill
  const [refill_no, setrefill_no] = useState("");
  const [refill_frequency, setrefill_frequency] = useState("");
  const [refill_date, setrefill_date] = useState("");

  // Dispensary Instructions
  const [DispensaryInstructions, setDispensaryInstructions] = useState("");
  const [formData, setFormData] = useState([]);
  const [ApplyFormValidation, setApplyFormValidation] = useState(0);
  const [dosageConverting, setDosageConverting] = useState(false);
  const [formulaConverting, setFormulaConverting] = useState(false);
  const [formulaConvertingTo, setFormulaConvertingTo] = useState(null);
  const [prevFormulaBuilderDosageMode, setPrevFormulaBuilderDosageMode] =
    useState(null);
  const [prevFormulaBuilderDosageModeKey, setPrevFormulaBuilderDosageModeKey] =
    useState(null);
  const [allowSubmitBtn, setAllowSubmitBtn] = useState(false);
  const [formulaTextBox, setformulaTextBox] = useState([]);
  const [formulaLabelSetting, setFormulaLabelSetting] = useState(null);
  useEffect(() => {
    getFormualDetails();
  }, [formulaId]);

  useEffect(() => {
    getApiDosagesData();
  }, [activeDosages]);

  useEffect(() => {
    const fetchFormulaDetails = async () => {
      try {
        findPrac();
        if (formulaBuilderId && formulaBuilderId != 0) {
          const response = await getData();
          if (!response || typeof response.id === "undefined") {
            setData(null);
            setformulaError(true);
            setformulaErrorMsg(
              "Current Formula Builder Type is not available."
            );
            return;
          }
          if (response && response.length !== 0 && response.id) {
            setLoading(false);
            setCurrentformulaBuilderId(response.id);

            const finalSectionsData = response.sections
              ? JSON.parse(response.sections)
              : [];

            const tagsOfIngrediuents = response.tagsOfIngrediuents
              ? JSON.parse(response.tagsOfIngrediuents)
              : [];
            navigate(`/${pracId}/${formulaBuilderId}/${formula}/${is_order}`);
            setData(response);
            const save_formula_after = response.save_formula_after
              ? parseInt(response.save_formula_after) * 1000
              : 60000; // Default to 60 seconds
            setAutoSaveAfter(save_formula_after);

            const seperatorText = response.default_seperator || "";
            setSeperatorText(seperatorText);

            const seperator = updateseperatorSign(seperatorText);
            const ingredient_selection_type =
              response.ingredient_selection_type || "";
            if (response && seperator && ingredient_selection_type) {
              setformulaSections(finalSectionsData);
              setIngrediuentsTags(tagsOfIngrediuents);
              setallowMarkup(response.markup || 0);
              updatePracSetting(response.name);
              // Dosages setup
              handleDosagesModeType();
              getApiDosagesData(); // Fetch dosages data
            }
          }
        } else {
          findFormulaBuildes();
        }
        setAllIngredientsResponse([]);
      } catch (error) {
        console.error("Error fetching formula details:", error);
        setformulaError(true);
        setformulaErrorMsg("An error occurred while fetching formula details.");
      }
    };
    fetchFormulaDetails();
  }, [formulaBuilderId]);

  useEffect(() => {
    // Set up an interval to call the function every 30 seconds
    const intervalId = setInterval(() => {
      if (data != null && formulaId != "" && formulaId != undefined) {
        handleSaveFormula();
      }
    }, 30000); // 30,000 milliseconds = 30 seconds
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const findPrac = () => {
    if (validPrac == false && pracId) {
      const pracDetails = getPrac();
      pracDetails.then((response) => {
        if (response?.id) {
          setValidPrac(true);
          findFormulaBuildes();
          setPracDetails(response);
        }
      });
    }
  };
  const updatePracSetting = (name) => {};
  const findFormulaBuildes = () => {
    var formula_list = getFormulaBuilderList();
    formula_list.then((response) => {
      setformulaList(response);
      if (!formulaBuilderId || formulaBuilderId == undefined) {
        Object.values(response).map((i, k) => {
          if (k == 0) {
            setformulaBuilderId(i.id);
          }
        });
      }
    });
  };

  const fontStyle = {
    fontFamily: data?.text_font ? data.text_font : "inherit",
  };

  const getData = async () => {
    setLoading(true);
    // track prevs Builder Type Data
    if (formulaBuilderId != prevFormulaBuilderId) {
      setPrevData(data ? data : "");
      setprevFormulaBuilderType(data ? data.display_name : "");
      setprevFormulaBuilderIngredients(data ? data.formula_ingredients : []);
      setprevFormulaBuilderId(formulaBuilderId);
    }
    const response = await ApiFormulaBuilderDetails({
      formulaBuilderId,
      pracId,
    });
    const finalData = await response;
    return finalData;
  };

  const getFormualDetails = async () => {
    const response = await ApiFormulaDetails({
      formulaBuilderId,
      pracId,
      formulaId,
      is_order,
    });
    const finalData = await response;
    setformulaDeatils(finalData ? finalData : []);
    if (
      finalData &&
      finalData.formula_builder_id != formulaBuilderId &&
      finalData.formula_builder_id != 0
    ) {
      setformulaBuilderId(finalData.formula_builder_id);
    }
  };

  const getApiDosagesData = async () => {
    var formulaDosagesType = activeDosages
      ? activeDosages
      : formulaBuilderDosageModeType;
    var ingredient_selection_type = data?.ingredient_selection_type;
    if (formulaBuilderId && formulaDosagesType) {
      try {
        const response = await ApiFormulaBuilderDosagesDetails({
          formulaBuilderId,
          formulaDosagesType,
          pracId,
          ingredient_selection_type,
        });
        const finalData = await response;
        // setdosageSettings(finalData); // to avoid dosage  re-render issue
        if (JSON.stringify(dosagesData) !== JSON.stringify(finalData)) {
          setdosageSettings(finalData);
        }

        // setdosageSettings((prevSettings) => {
        //   if (JSON.stringify(prevSettings) !== JSON.stringify(finalData)) {
        //     return finalData;
        //   }
        //   return prevSettings;
        // });

        Object.entries(finalData).forEach(([key, value]) => {
          if (value.key_value === "DOSAGE_INPUT_NUMBER_OF_DAYS") {
            if (dosageConverting == true) {
              if (
                !numberDays ||
                numberDays == 0 ||
                typeof numberDays == undefined
              ) {
                handleNumberDaysChange(value.value);
              } else {
                value.value = numberDays;
              }
            }
          }
        });
      } catch (error) {
        console.log("testtt", error);
      }
    }
  };

  const getFormulaBuilderList = async () => {
    getFormulaExtraData();
    const response = await ApiFormulaBuilderList({ pracId });
    return response;
  };

  const getFormulaIngredientList = async (
    separator,
    ingredient_selection_type,
    allow_ingredients = []
  ) => {
    const allowed_ingredients =
      allow_ingredients.length > 0 ? allow_ingredients.join(",") : "";

    let response = null;

    if (allIngredientsResponse.length === 0) {
      try {
        setLoadingTable(true);

        response = await ApiFormulaBulderIngredientLists({
          formulaBuilderId,
          pracId,
          ingredient_selection_type,
          allowed_ingredients,
        });

        if (allowed_ingredients && response?.ingredients?.length) {
          setAllIngredientsResponse(response);
        }
      } catch (error) {
        console.error("Error fetching ingredients:", error);
      } finally {
        setLoadingTable(false);
      }
    } else {
      const ingrediantsArray = allIngredientsResponse;
      const filteredIngrediantsArray =
        ingrediantsArray?.ingredients?.filter((item) =>
          allow_ingredients.includes(item.ingredient_id.toString())
        ) || [];

      response = {
        ...ingrediantsArray,
        ingredients: filteredIngrediantsArray,
        total_ingredients: filteredIngrediantsArray.length,
      };
    }

    if (response && response.ingredient_selection_type) {
      const {
        ingredients,
        fields,
        names,
        fieldname,
        ingredient_decimal,
        filter,
      } = response;

      // Use functional setState to prevent unnecessary renders if state has not changed
      setNameTypes((prevNames) => (prevNames !== names ? names : prevNames));
      setingredientNamePostFix((prevFields) =>
        prevFields != fields ? fields : prevFields
      );
      setformulaIngredients((prevIngredients) =>
        prevIngredients != ingredients ? ingredients : prevIngredients
      );
      setingredient_decimal((prevDecimal) =>
        prevDecimal != ingredient_decimal ? ingredient_decimal : prevDecimal
      );

      // Update formula ingredient list efficiently
      updateFormulaIngredientList(
        ingredients,
        fields,
        fieldname,
        separator,
        names,
        ingredient_decimal,
        filter
      );
    }
  };

  const getFormulaExtraData = async () => {
    const response = await ApiFormulaExtraData({
      formulaBuilderId,
      pracId,
    });
    if (response) {
      const { measurment_conversions, name_types } = response;
      // Update state with the received data
      setNameTypeArr(name_types ? JSON.parse(name_types) : []);
      setMeasurmentConversions(
        measurment_conversions ? JSON.parse(measurment_conversions) : []
      );
    }
  };
  const getPrac = async () => {
    const response = await ApiVerifyPrac({ pracId });
    return response;
  };

  const updateseperatorSign = (seperator) => {
    let separatorSign = ""; // Default value

    // Check if the selected separator matches any of the given options
    switch (seperator) {
      case "dot":
        separatorSign = " ● ";
        break;
      case "rounddot":
        separatorSign = " ⦿ ";
        break;
      case "comma":
        separatorSign = " , ";
        break;
      case "pipeline":
        separatorSign = " | ";
        break;
      case "hyphen":
        separatorSign = " - ";
        break;
      case "forwardSlash":
        separatorSign = " / ";
        break;
      case "star":
        separatorSign = " * ";
        break;
      case "greater":
        separatorSign = " < ";
        break;
      case "smaller":
        separatorSign = " > ";
        break;
      // You can add more cases here if needed
      default:
        // If none of the options match, set an empty string.
        separatorSign = " ";
    }

    // Update the state
    setSeperatorSign(separatorSign);

    // Return the selected separator sign
    return separatorSign;
  };

  const updateFormulaIngredientList = (
    formulaIngredients,
    extra,
    FieldsName,
    seperator,
    namesType,
    ingredient_decimal,
    filter
  ) => {
    var IngredientOptionArr = [];
    var IngredientsIdArr = [];
    IngredientOptionArr["formula_dosage_column_all_names"] = [];
    IngredientOptionArr["name_type"] = namesType;
    IngredientOptionArr["all_ingredients"] = formulaIngredients;
    IngredientOptionArr["filter"] = filter;

    if (
      formulaIngredients &&
      formulaIngredients.length != 0 &&
      formulaIngredients != null
    ) {
      var yesNoFields = new Set([
        "toxic",
        "external_use_only",
        "aristolic_acid",
        "contains_cholesterol",
        "gmo_free",
        "contains_mustard",
        "contains_sulphites",
        "contains_sesame",
        "contains_nuts",
        "madeInaPeanutFreeFacility",
        "caution_with_pregnancy",
        "organic",
        "kosher",
        "wildcrafted",
        "contains_gluten",
        "contains_corn",
        "contains_egg",
        "contains_animal_products",
        "contains_wheat",
        "contains_fish",
        "contains_soy",
        "contains_artificial_sweeters",
        "contains_peanuts",
        "contains_milk",
        "contains_shellfish",
        "contains_treenuts",
        "vegan",
      ]);

      namesType.map((name) => {
        //var name = 'name'
        var key = "formula_dosage_column_" + name;
        var ingredientOtherNameOptions = [];
        var ingredientOptions = formulaIngredients.map((item, kk) => {
          if (item.product_type == "herbs") {
            if (item[name] != "") {
              var labelValue = item[name];
              var ing_name = item[name];
              var display_name = item[name];
            } else {
              // var labelValue = item['default_herb_type']
              //   ? item['default_herb_type']
              //   : null
              // var ing_name = labelValue
              // var display_name = labelValue
            }
            if (labelValue) {
              var other_names_arr = [];
              //labelValue += seperator + item.ing_name // Ingredient Type
              var other_names = item["other_names"] ? item["other_names"] : "";
              if (other_names && other_names != "" && other_names != null) {
                var other_names_array = other_names
                  ? JSON.parse(other_names)
                  : [];
                if (other_names_array && other_names_array.length != 0) {
                  other_names_array.forEach((obj) => {
                    var thisNameType = obj.name_type;
                    if (thisNameType == name && obj.name) {
                      other_names_arr.push(obj.name);
                    }
                  });
                }
              }
              var extra_name_data = "";
              if (extra.length != 0) {
                extra.map((i) => {
                  i = i.trim();
                  if (i == "ratio,ratio2") {
                    if (
                      item.ratio !== undefined &&
                      item.ratio2 !== undefined &&
                      item.ratio != "" &&
                      item.ratio2 != ""
                    ) {
                      labelValue += seperator + item.ratio + ":" + item.ratio2;
                      extra_name_data +=
                        seperator + item.ratio + ":" + item.ratio2;
                    }
                  } else if (
                    item[i] != "" &&
                    item[i] != null &&
                    item[i] !== undefined
                  ) {
                    if (yesNoFields.has(i)) {
                      if (item[i] == "1") {
                        labelValue += seperator + FieldsName[i];
                        extra_name_data += seperator + FieldsName[i];
                      }
                    } else {
                      labelValue += seperator + item[i];
                      extra_name_data += seperator + item[i];
                    }
                  }
                });
              }
              var process_type = "";
              if (
                item["process_type"] != "" &&
                item["process_type"] != null &&
                item["process_type"] !== undefined
              ) {
                display_name += seperator + item["process_type"];
                process_type += seperator + item["process_type"];
              }

              IngredientsIdArr.push(item.id);
              // add other name in options
              if (other_names_arr.length != 0) {
                other_names_arr.forEach((obj) => {
                  var this_other_name = obj;
                  if (
                    this_other_name &&
                    this_other_name != "" &&
                    this_other_name != null
                  ) {
                    var slug = this_other_name
                      .toLowerCase()
                      .replace(/\s+/g, "_");
                    ingredientOtherNameOptions.push({
                      ing_name: this_other_name,
                      label: this_other_name + extra_name_data,
                      display_name: this_other_name + process_type,
                      value: item.id,
                      key: name + "_" + item.id + "_" + slug,
                      herb_type: item.ing_key,
                      ing_id: item.ingredient_id,
                    });
                  }
                });
              }
              return {
                ing_name: decodeEntities(ing_name),
                label: decodeEntities(labelValue),
                display_name: decodeEntities(display_name),
                value: item.id,
                key: name + "_" + item.id + "_" + kk,
                herb_type: item.ing_key,
                ing_id: item.ingredient_id,
              };
            }
          }
        });
        if (
          ingredientOtherNameOptions &&
          ingredientOtherNameOptions.length != 0
        ) {
          ingredientOptions = [
            ...ingredientOptions,
            ...ingredientOtherNameOptions,
          ];
        }

        ingredientOptions.sort((a, b) => a.label.localeCompare(b.label));
        // Filter out null and undefined elements from the array
        // Filter out null and undefined elements from the array
        const filteredArray = ingredientOptions.filter(
          (item) => item !== null && item !== undefined
        );
        var mergedItems = [
          ...IngredientOptionArr["formula_dosage_column_all_names"],
          ...filteredArray,
        ];
        mergedItems.sort((a, b) => a.label.localeCompare(b.label));
        IngredientOptionArr["formula_dosage_column_all_names"] = mergedItems;
        IngredientOptionArr[key] = filteredArray;
      });
      // IngredientOptionArr['formula_dosage_column_all_names'] =
      //   IngredientOptionArr['formula_dosage_column_name']
      ///////////////////////////////////////////////////////////
      var IngredientsIdArr = Array.from(new Set(IngredientsIdArr));
      setformulaIngredientsIdArr(IngredientsIdArr);
      //setformulaIngredients(IngredientOptionArr)
      ///////////////////////////////////////////////////////////
    } else {
      setformulaIngredientsIdArr([]);
      // setformulaIngredients([])
    }
    setformulaIngredients(IngredientOptionArr);
  };

  useEffect(() => {
    getApiDosagesData();
  }, [formulaBuilderDosageModeType, formulaBuilderDosageMode]);

  const handleDosagesModeType = (id = 0) => {
    var types = data ? JSON.parse(data.dosage_section) : [];
    if (id != null && id != 0 && formulaBuilderDosageModeType != null) {
      Object.values(types).map((i, k) => {
        if (id == i.id) {
          setformulaBuilderDosageMode(i.id);
          setformulaBuilderDosageModeType(i.value);
          setformulaBuilderDosageModeKey(i.key_value);
          setformulaDosagesSetting(i);
        }
      });
    } else if (
      formulaBuilderDosageModeType == null ||
      formulaBuilderDosageModeType == "" ||
      formulaBuilderDosageModeType == "0" ||
      formulaBuilderDosageMode == null ||
      formulaBuilderDosageMode == "" ||
      formulaBuilderDosageMode == 0 ||
      id == "" ||
      id == "null" ||
      id == 0
    ) {
      Object.values(types).map((i, k) => {
        if (k == 0) {
          setformulaBuilderDosageMode(i.id);
          setformulaBuilderDosageModeType(i.value);
          setformulaBuilderDosageModeKey(i.key_value);
          setformulaDosagesSetting(i);
          setformulaBuilderDosageMode(i.id);
        }
      });
    } else {
      Object.values(types).map((i, k) => {
        if (k == 0) {
          setformulaBuilderDosageMode(i.id);
          setformulaBuilderDosageModeType(i.value);
          setformulaBuilderDosageModeKey(i.key_value);
          setformulaDosagesSetting(i);
          setformulaBuilderDosageMode(i.id);
        }
      });
    }
  };

  // OnChange Ingredient Selection, Ingredient list according to thse setting
  useEffect(() => {
    // Update formula ingredient list with the received data
    var seperator = updateseperatorSign(data ? data.default_seperator : "");
    var ingredient_selection_type = data ? data.ingredient_selection_type : "";

    if (
      data &&
      data.ingredient_selection_type &&
      ingredient_selection_type != ""
    ) {
      getFormulaIngredientList(
        seperator,
        ingredient_selection_type,
        ingredient_selection
      );
    }
    // console.log(ingredient_selection, "ingredient_selection")
  }, [ingredient_selection]);

  // ##############################################
  // General Section data handle
  const handleGeneralSectionChange = (
    newFormulaBuilderType,
    newFormulaBuilderName,
    newFormulaBuilderDecoction,
    newFormulaBuilderPatient,
    newFormulaBuilderDosageMode,
    newFormulaBuilderShareFormula,
    newFormulaBuilderPractice,
    newFormulaBuilderSupervisor,
    newFormulaBuilderTemplate,
    newFormulaConverting,
    newFormulaConvertingTo
  ) => {
    setformulaBuilderId(newFormulaBuilderType); // Update the formulaBuilderId when the select value changes
    setformulaBuilderDosageMode(newFormulaBuilderDosageMode);
    if (newFormulaBuilderDosageMode != formulaBuilderDosageMode) {
      setPrevFormulaBuilderDosageMode(formulaBuilderDosageMode);
      setPrevFormulaBuilderDosageModeKey(formulaBuilderDosageModeKey);
    }
    handleDosagesModeType(newFormulaBuilderDosageMode); // convert dosagemode id to vale like per,daily amd bag
    setformulaBuilderName(newFormulaBuilderName);
    setformulaBuilderDecoction(newFormulaBuilderDecoction);
    setformulaBuilderPatient(newFormulaBuilderPatient);
    setformulaBuilderShareFormula(newFormulaBuilderShareFormula);
    setformulaBuilderPractice(newFormulaBuilderPractice);
    setformulaBuilderSupervisor(newFormulaBuilderSupervisor);
    setformulaBuilderTemplate(newFormulaBuilderTemplate);
    setFormulaConverting(newFormulaConverting);
    setFormulaConvertingTo(newFormulaConvertingTo);
  };

  // Option Section data handle
  const handleOptionSectionChanges = (
    newtotalQty,
    newisCapsuleSelected,
    newcapsuleSizeSelected,
    newMinBasedQty,
    newMaxBasedQty
  ) => {
    setTotalQty(newtotalQty);
    setIsCapsuleSelected(newisCapsuleSelected);
    setCapsuleSizeSelected(newcapsuleSizeSelected);
    setMinBasedQty(newMinBasedQty);
    setMaxBasedQty(newMaxBasedQty);
  };

  const handleIngredientSelection = (new_ingredient_selection) => {
    setingredient_selection(new_ingredient_selection);
  };
  const updateDefaultMeasuremt = (
    newdefaultMeasuremt,
    newdefaultMeasuremtName,
    newmeasurementOptions,
    newallow_multiple_measurements
  ) => {
    setdefaultMeasuremt(newdefaultMeasuremt);
    setdefaultMeasuremtName(newdefaultMeasuremtName);
    setmeasurementOptions(newmeasurementOptions);
    setallow_multiple_measurements(newallow_multiple_measurements);
  };
  // Dosages Section data handle
  const HandleDosagesSectionChanges = (
    newusePresetDosageInstructions,
    newdosagesData,
    newallowOwnDosagesInstructions,
    newownDosagesInstructions,
    newdosagesText,
    newautomaticallyCalculateDosages,
    newallowUserToChangeAutomaticallyCalculateDosages
  ) => {
    setUsePresetDosageInstructions(newusePresetDosageInstructions);
    setdosagesData(newdosagesData);
    setdosagesText(newdosagesText);
    setAllowOwnDosagesInstructions(newallowOwnDosagesInstructions);
    setOwnDosagesInstructions(newownDosagesInstructions);
    setAutomaticallyCalculateDosages(newautomaticallyCalculateDosages);
    setAllowUserToChangeAutomaticallyCalculateDosages(
      newallowUserToChangeAutomaticallyCalculateDosages
    );
  };
  // ingredient section data handle
  const handleIngredientSectionChanges = (newIngredients) => {
    if (newIngredients && newIngredients.length != 0) {
      setIngredients(newIngredients);
      const finalSubTotalArr = newIngredients.map(
        (v) => parseFloat(v.formula_dosage_column_subtotal) || 0
      );
      const finalSubTotalSum = finalSubTotalArr.reduce(
        (accumulator, currentValue) =>
          parseFloat(accumulator) + parseFloat(currentValue),
        0
      );
      setPriceArr(finalSubTotalArr);
      setPrice(finalSubTotalSum);
    }
  };
  // Addon servies
  const handleServiesSectionChanges = (newServies) => {
    setaddOnServices(newServies);
  };
  const handleAddOnServicesPrice = (newSelected) => {
    setSelectedAddonServices(newSelected);
  };
  // Markup Section data handle
  const handleMarkupSectionChanges = (
    newallowMarkup,
    newmarkup_type,
    newmarkup_amount
  ) => {
    setallowMarkup(newallowMarkup);
    setmarkup_type(newmarkup_type);
    setmarkup_amount(newmarkup_amount);
  };
  // Price Section data handle
  const handlePriceSectionChanges = (
    newingredientTotal,
    newaddOnServiesTotal,
    newsubtotalPrice,
    newmarkupTotal,
    newtotalPrice,
    newfeesTotal,
    newcapsulefeesTotal
  ) => {
    setIngredientsTotal(newingredientTotal);
    setAddOnServiesTotal(newaddOnServiesTotal);
    setSubTotalPrice(newsubtotalPrice);
    setMarkupTotal(newmarkupTotal);
    setTotalPrice(newtotalPrice);
    setFeesTotal(newfeesTotal);
    setcapsulefeesTotal(newcapsulefeesTotal);
  };
  const HandlePreprationInstructionsSectionChanges = (newherbTags) => {
    setHerbTags(newherbTags);
  };
  const HandleBlankPreprationInstructionsSectionChanges = (newData) => {
    setBlankHerbTags(newData);
  };
  //Refill section data handle
  const handleRefillSectionChange = (
    newrefill_no,
    newrefill_frequency,
    newrefill_date
  ) => {
    setrefill_no(newrefill_no);
    setrefill_frequency(newrefill_frequency);
    var formattedDate = newrefill_date
      ? format(new Date(newrefill_date), "yyyy-MM-dd'T'HH:mm:ss'Z'")
      : null;
    setrefill_date(formattedDate);
  };

  const handleNumberDaysChange = (numberDays) => {
    if (numberDays != undefined) {
      setNumberDays(numberDays);
      Object.values(dosageSettings).map((array, key) => {
        if (array.key_value === "DOSAGE_INPUT_NUMBER_OF_DAYS") {
          array.value = numberDays;
        }
      });
    }
  };

  const handleNumberBagsChange = (newnumberBags) => {
    if (newnumberBags != undefined) {
      setNumberBags(newnumberBags);
    }
  };

  const handleDosagesInputQtyChange = (newdosagesQty) => {
    if (newdosagesQty != undefined) {
      //if (formulaDosagesSetting && formulaDosagesSetting.weight_volume == "1") {
        setDosagesTotalQty(newdosagesQty);
      //}
    }else{
      setDosagesTotalQty(0);
    }
  }

  const handleActiveDosageChange = (newActiveDosages) => {
    setActiveDosages(newActiveDosages);
  };

  const handleActiveOptionDosagesData = (array) => {
    setActiveOptionDosagesData(array);
  };

  const handletotalQty = (data) => {
    if (formulaDosagesSetting && formulaDosagesSetting.weight_volume == "1") {
      setFormulaTotalQty(data);
    }
  };
  const handleFormulaOption = (data) => {
    setFormulaOption(data);
  };

  const handleDispensaryInstructions = (value) => {
    setDispensaryInstructions(value);
  };

  // function to update form data state
  const handleFormData = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };
  //############################################
  const handleSectionFormData = (newData, index) => {
    if (index == "FORMULAS_OPTIONS") {
    }
    setformulaFormData((prevData) => {
      return {
        ...prevData,
        [index]: newData,
      };
    });
  };

  const getFormData = async () => {
    var IngredientArr = ingredients.filter(
      (v) => v.ingredient_id !== null && v.ingredient_id !== ""
    );
    var ServicesArr = addOnServices;
    var formArr = {
      id: formulaId,
      product_type: "custom_formula",
      saved_formula: 1,
      formula_builder_id: formulaBuilderId,
      herb_type_common: data.name,
      //# General Section ############################
      name: formulaBuilderName,
      is_decocation: formulaBuilderDecoction,
      patient_id: formulaBuilderPatient,
      formula_t: formulaBuilderDosageMode,
      formula_state: formulaBuilderShareFormula,
      practice: formulaBuilderPractice, //##
      supervisor: formulaBuilderSupervisor, //##
      template: formulaBuilderTemplate, //##
      //# Formula Options section #####################
      measurement: defaultMeasuremt, //##
      dosage_quantity: numberBags, //##
      dosage_noof_days: numberDays,
      min_base_qty: minBasedQty, //##
      max_base_qty: maxBasedQty, //##
      set_totalweight_formula: totalQty,
      // # Dosages Section #################################
      do_not_use_on_label: usePresetDosageInstructions,
      dosage_input: dosagesData, //##
      dosage_input_preview: dosagesText, //##
      allow_additional_info_formula: allowOwnDosagesInstructions, //##
      additional_info_formula: ownDosagesInstructions,
      //additional_info_formula:
      // # Ingredient Section ##############################
      ingredients: IngredientArr, //IngredientArr, // if formula saving issue then cooment this line
      // # Pre Section ##############################
      preparation_instructions: herbTags,
      blank_preparation_instructions: blankHerbTags,
      // # Add On Services #################################
      add_on_services_details: ServicesArr, //addOnServices,
      selected_add_on_services: selectedAddonServices,
      // # Markup #################################
      allow_markup: data.markup,
      markup_type: markup_type,
      markup_amount: markup_amount,
      // # Price #################################
      ingredient_price: ingredientTotal,
      add_on_services_price: addOnServiesTotal,
      subtotal: subtotalPrice,
      markup_price: markupTotal,
      fees: feesTotal,
      capsule_fees: capsulefeesTotal,
      total_price: totalPrice,
      // # Refill  #################################
      refill_days: refill_no,
      refill_frequency: refill_frequency,
      refill_end_date: refill_date,
      // ...other properties

      dispensary_instructions: DispensaryInstructions,
      extra_data: formulaFormData, // Important
      is_verified: "0",
      seleted_ingredient_type: seletedIngredientType,

      // ingredient_selection_type: data?.ingredient_selection_type,
      // allow_multiple_measurements:allow_multiple_measurements,
      // defaultMeasuremt:defaultMeasuremt,
    };
    if (is_order == "true") {
      formArr["order_id"] = formulaDeatils?.order_id;
      formArr["product_id"] = formulaDeatils?.order_id;
      formArr["id"] = formulaDeatils?.id;
    }

    if (activeDosages == "capsules") {
      formArr["is_capsule"] = "1";
      formArr["is_tablet"] = "0";
      formArr["is_softgel"] = "0";
      formArr["capsule_size"] = capsuleSizeSelected;
      formArr["selected_capsule_details"] = activeOptionDosagesData;
    } else if (activeDosages == "tablets") {
      formArr["is_tablet"] = "1";
      formArr["is_capsule"] = "0";
      formArr["is_softgel"] = "0";
      formArr["capsule_size"] = capsuleSizeSelected;
      formArr["selected_capsule_details"] = activeOptionDosagesData;
    } else if (activeDosages == "softgels") {
      formArr["is_capsule"] = "0";
      formArr["is_tablet"] = "0";
      formArr["is_softgel"] = "1";
      formArr["capsule_size"] = capsuleSizeSelected;
      formArr["selected_capsule_details"] = activeOptionDosagesData;
    } else if (activeDosages == "") {
      formArr["is_capsule"] = "0";
      formArr["is_tablet"] = "0";
      formArr["is_softgel"] = "0";
      formArr["base_ingredient"] = capsuleSizeSelected;
      formArr["selected_capsule_details"] = activeOptionDosagesData;
    } else {
      formArr["is_capsule"] = "0";
      formArr["is_tablet"] = "0";
      formArr["is_softgel"] = "0";
      formArr["capsule_size"] = "";
      formArr["base_ingredient"] = capsuleSizeSelected;
      formArr["selected_capsule_details"] = activeOptionDosagesData;
    }
    return formArr;
  };

  const formValidation = async () => {
    const formData = getFormData();
    var errors = [];
    var sectionError = [];
    Object.values(formulaFormData).forEach((fields, key) => {
      Object.values(fields).map(function (item, index) {
        var error = item?.error;
        if (error != "" && error != null && error != undefined) {
          if (typeof error === "string") {
            sectionError.push({ name: item.key, error: error });
            errors.push(error);
          } else {
            Object.values(error).forEach((i) => {
              if (i != "" && i != null && i != undefined) {
                sectionError.push({ name: item.key, error: i });
                errors.push(i);
              }
            });
          }
        }
      });
    });
    return errors;
  };

  // function to handle form submission
  const handleSubmitM = async (event = "", auto = false) => {
    if (event != "") {
      event.preventDefault();
    }
    if (!auto) {
      setApplyFormValidation(1);
      var validate = await formValidation();
      const validateArr = Array.from(new Set(validate));
      if (validateArr.length !== 0 && auto == false) {
        validateArr.map((msg) => {
          toaster.push(
            <Notification type="error" header={msg}></Notification>,
            {
              placement: "topEnd",
              duration: 5000,
            }
          );
          return false;
        });
        return false;
      }
      if (CheckFormulaIngredientPrice(ingredients) == false) {
        toaster.push(
          <Notification
            type="error"
            header={"Ingredient Price could not be zero."}
          ></Notification>,
          {
            placement: "topEnd",
            duration: 5000,
          }
        );
        return false;
      }
      var FORMULAS_OPTIONS_DATA =
        formulaFormData && formulaFormData["FORMULAS_OPTIONS"]
          ? formulaFormData["FORMULAS_OPTIONS"]
          : [];
      var bundleWeightVolume = await CheckFormlaBundleweight(
        FORMULAS_OPTIONS_DATA
      );
      if (bundleWeightVolume && bundleWeightVolume.length != 0) {
        bundleWeightVolume.map((i) => {
          toaster.push(
            <Notification type="error" header={i.msg}></Notification>,
            {
              placement: "topEnd",
              duration: 5000,
            }
          );
          return false;
        });
        return false;
      }
    }

    const formData = await getFormData();
    if (!formulaId || !data.id) {
      toaster.push(
        <Notification
          type="error"
          header="Formula Id is missing."
        ></Notification>,
        {
          placement: "topEnd",
          duration: 5000,
        }
      );
      return false;
    }
    const response = await ApiSaveFormulaDetails({
      formulaBuilderId,
      pracId,
      formulaId,
      formData,
      is_order,
    });
    var position = auto == true ? "topEnd" : "bottomCenter";
    const msg = auto ? "Synced" : "Formula Saved Successfully";
    toaster.push(<Notification type="success" header={msg}></Notification>, {
      placement: position,
      duration: 5000,
    });
    if (!auto) {
      if (is_order == "true") {
        // redirect to Order Details
        var url =
          BASEURL +
          "admin/control.php?Page=order&action=editorder&section=editorder&oid=" +
          formulaDeatils?.order_id;
        openURL(url, "_parent");
      } else {
        var url = BASEURL + "?page=formula-review-new&" + "id=" + formulaId;
        openURL(url, "_parent");
      }
    }
  };

  const handleSubmit = async (auto = false, url = "") => {
    if (!auto) {
      setApplyFormValidation(1);
      var validate = await formValidation();
      const validateArr = Array.from(new Set(validate));
      if (validateArr.length !== 0 && auto == false) {
        validateArr.map((msg) => {
          toaster.push(
            <Notification type="error" header={msg}></Notification>,
            {
              placement: "topEnd",
              duration: 5000,
            }
          );
          return false;
        });

        return false;
      }
      var checkIng = await CheckFormulaIngredientPrice(ingredients);
      if (checkIng == false) {
        toaster.push(
          <Notification
            type="error"
            header={"Ingredient Price could not be zero."}
          ></Notification>,
          {
            placement: "topEnd",
            duration: 5000,
          }
        );
        return false;
      }
      var FORMULAS_OPTIONS_DATA =
        formulaFormData && formulaFormData["FORMULAS_OPTIONS"]
          ? formulaFormData["FORMULAS_OPTIONS"]
          : [];
      var bundleWeightVolume = await CheckFormlaBundleweight(
        FORMULAS_OPTIONS_DATA
      );
      if (bundleWeightVolume && bundleWeightVolume.length != 0) {
        // Collect all error messages into a single string
        bundleWeightVolume.map((i) => {
          setBundleErrMsg(i.msg);
          setBundlePopup(true);
          // toaster.push(
          //   <Notification type="error" header={i.msg}></Notification>,
          //   {
          //     placement: "topEnd",
          //     duration: 5000,
          //   }
          // );
          return false;
        });

        return false;
      }
    }

    const formData = await getFormData();
    if (
      !formData.name ||
      formData.name.trim() === "" ||
      formData.name == null ||
      formData.name == undefined
    ) {
      toaster.push(
        <Notification
          type="error"
          header="A Formula Name is Required."
        ></Notification>,
        {
          placement: "topEnd",
          duration: 5000,
        }
      );
      return false;
    }
    // if(!formData.patient_id ||
    //   formData.patient_id.trim() === "" ||
    //   formData.patient_id == null ||
    //   formData.patient_id == undefined){
    //   toaster.push(
    //     <Notification
    //       type="error"
    //       header="A Patient is required to be selected for this  Formula"
    //     ></Notification>,
    //     {
    //       placement: "topEnd",
    //       duration: 5000,
    //     }
    //   );
    //   return false;
    // }
    if (!formulaId || !data.id) {
      toaster.push(
        <Notification
          type="error"
          header="Formula Id is missing."
        ></Notification>,
        {
          placement: "topEnd",
          duration: 5000,
        }
      );
      return false;
    }

    const response = await ApiSaveFormulaDetails({
      formulaBuilderId,
      pracId,
      formulaId,
      formData,
      is_order,
    });
    const finalData = await response;
    // console.log("save form data responce", finalData, {
    //   formulaBuilderId,
    //   pracId,
    //   formulaId,
    //   formData,
    // });
    var position = auto == true ? "topEnd" : "bottomCenter";
    const msg = auto ? "Synced" : "Formula Saved Successfully";
    toaster.push(<Notification type="success" header={msg}></Notification>, {
      placement: position,
      duration: 5000,
    });
    return true;
  };
  const openURL = (url, target = "_blank", features = "") => {
    window.open(url, target, features);
  };
  useEffect(() => {
    if (formulaConverting == true && formulaConvertingTo != formulaBuilderId) {
      buildNewFormula();
    }
  }, [formulaConverting, formulaConvertingTo]);
  const buildNewFormula = async () => {
    handleSaveFormula("convert");
  };
  useEffect(() => {
    IngredientformValidation();
  }, [ingredients]);
  useEffect(() => {
    UpdateFormulaBuilderSetting();
  }, [data]);

  const IngredientformValidation = () => {
    const filteredIngredients = ingredients.filter((ingredient) => {
      return (
        ingredient.ingredient_id != null &&
        ingredient.ingredient_id != "" &&
        ingredient.ingredient_id != undefined
      );
    });
    const totalQtyReceived = filteredIngredients.reduce(
      (accumulator, ingredient) => {
        // Convert formula_dosage_column_qty_received to a number using parseFloat
        const qtyReceived = parseFloat(
          ingredient.formula_dosage_column_qty_received
        );
        // Check if qtyReceived is a valid number
        if (!isNaN(qtyReceived)) {
          // Add the valid qtyReceived to the accumulator
          return accumulator + qtyReceived;
        }
        // If formula_dosage_column_qty_received is not a valid number, return the current accumulator
        return accumulator;
      },
      0
    );
    var requirements = [
      {
        column: "ingredients",
        name: "Ingredients",
        is_required: 0,
        key: "ingredients",
        allow_zero: false,
      },
      {
        column: "ingredients_count",
        name: "Ingredients Count",
        is_required: 1,
        key: "ingredients_count",
        allow_zero: false,
      },
      {
        column: "ingredients_qty",
        name: "Ingredients Total Qty",
        is_required: 1,
        key: "ingredients_qty",
        allow_zero: false,
      },
      {
        column: "ingredients_herb_tags",
        name: "Ingredients Prepration Ins. Tags",
        is_required: 0,
        key: "ingredients_herb_tags",
        allow_zero: false,
      },
      {
        column: "ingredient_selection_type",
        name: "Ingredients Selection Type",
        is_required: 0,
        key: "ingredient_selection_type",
        allow_zero: false,
      },
      {
        column: "allow_multiple_measurement_types_in_same_formula",
        name: "allow_multiple_measurement_types_in_same_formula",
        is_required: 0,
        key: "allow_multiple_measurement_types_in_same_formula",
        allow_zero: false,
      },
    ];
    var formError = {
      ingredients: "",
      ingredients_count: "",
      ingredients_qty: "",
    };
    var formValue = {
      ingredients: filteredIngredients,
      ingredients_count: filteredIngredients.length,
      ingredients_qty: totalQtyReceived,
      ingredients_herb_tags: ingrediuentsTags ? ingrediuentsTags : [],
      allow_multiple_measurement_types_in_same_formula:
        allow_multiple_measurements,
      ingredient_selection_type: data?.ingredient_selection_type,
    };

    var Final_array = [];
    if (requirements) {
      Object.values(requirements).map((i, key) => {
        if (i.is_required == 1) {
          if (i.allow_zero == true) {
            if (
              String(formValue[i.key]).trim() == "" ||
              formValue[i.key] == null ||
              formValue[i.key] == undefined
            ) {
              if (i.key == "ingredients_count") {
                formError[i.key] = "Please add min one ingredient";
              } else if (i.key == "ingredients_qty") {
                formError[i.key] = "Formual Weight/Volume can`t be emplty";
              } else {
                formError[i.key] = "This field is required";
              }
            } else {
              formError[i.key] = "";
            }
          } else {
            if (
              String(formValue[i.key]).trim() == "" ||
              formValue[i.key] == null ||
              formValue[i.key] == 0 ||
              formValue[i.key] == undefined
            ) {
              if (i.key == "ingredients_count") {
                formError[i.key] = "Please add min one ingredient";
              } else if (i.key == "ingredients_qty") {
                formError[i.key] = "Formual Weight/Volume can`t be emplty";
              } else {
                formError[i.key] = `${i.name} is required.`;
              }
            } else {
              formError[i.key] = "";
            }
          }
        }
        var temp = {
          ...i,
          error: formError[i.key],
          value: formValue[i.key],
        };
        Final_array.push(temp);
      });
    }
    handleSectionFormData(Final_array, "FORMULAS_FORMULA_INGREDIENTS");
  };
  const CheckFormlaBundleweight = async (array) => {
    var error = [];
    var ingredient_selection_type = data.ingredient_selection_type;
    var measurementList =
      data && data.measurment_type_list ? data.measurment_type_list : [];
    var active_dosages = "";
    var active_dosages_arr = "";
    var min_qty = 0;
    var max_qty = 0;
    var weight_volume = 0;
    if (array.length != 0) {
      array.map((item) => {
        if (item.column == "active_option_dosages") {
          active_dosages = item.value;
        } else if (item.column == "active_option_dosages_array") {
          active_dosages_arr = item.value;
        } else if (item.column == "min_qty") {
          min_qty = item.value;
        } else if (item.column == "max_qty") {
          max_qty = item.value;
        } else if (item.column == "weight_volume") {
          weight_volume = item.value;
        }
      });
    }

    var ingredientWeightVolumePerUnit = [];
    ingredients.forEach((item) => {
      const measurementid = item["default_measurment"];

      if (item && item.ingredient_id != 0 && item.ingredient_id != "") {
        var qty = item["formula_dosage_column_qty_received"]
          ? item["formula_dosage_column_qty_received"]
          : 0;
        if (qty) {
          ingredientWeightVolumePerUnit.push({ unit: measurementid, qty: qty });
        }
      }
    });
    var IngredientWeight = {};
    if (ingredientWeightVolumePerUnit.length != 0) {
      IngredientWeight = ingredientWeightVolumePerUnit.reduce(
        (result, current) => {
          const key = current.unit;
          var unit_name = "";
          const foundOption = Object.values(measurementList).find((option) => {
            return key == option["id"];
          });
          if (foundOption) {
            unit_name = foundOption?.display_name;
          }

          if (!result[key]) {
            result[key] = { unit: key, unit_name: unit_name, qty: 0 };
          }
          result[key].qty += parseFloat(current.qty);
          return result;
        },
        {}
      );
    }
    var rangeArr = [];
    var allow_range_setting = 0;
    var bundle_name = "";
    if (active_dosages_arr) {
      if (active_dosages_arr && active_dosages_arr.id && active_dosages == "") {
        bundle_name = "Base Ingredient";
        if (data.base_ingredient_required == 1) {
          if (
            ingredient_selection_type ==
            "ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE"
          ) {
            var temp = {
              unit: active_dosages_arr.unit,
              from: active_dosages_arr.weight_from,
              to: active_dosages_arr.weight_to,
            };
            rangeArr.push(temp);
            allow_range_setting = 1;
          } else if (
            ingredient_selection_type ==
            "ONE_INGREDIENT_TYPE_MULTIPLE_MEASUREMENT_TYPES"
          ) {
            if (allow_multiple_measurements == false) {
              var perUnit = active_dosages_arr.extra
                ? active_dosages_arr.extra
                : [];

              if (perUnit && Object.values(perUnit).length != 0) {
                Object.values(perUnit).map((item) => {
                  var temp = {
                    unit: item.measurement_type,
                    from: item.weight_from,
                    to: item.weight_to,
                  };
                  rangeArr.push(temp);
                });
              }
              allow_range_setting = 1;
            } else if (allow_multiple_measurements == true) {
              var temp = {
                unit: active_dosages_arr.measurement_type,
                from: active_dosages_arr.weight_from,
                to: active_dosages_arr.weight_to,
              };
              rangeArr.push(temp);
              allow_range_setting = 1;
            }
          } else if (
            ingredient_selection_type ==
            "MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_PER_INGREDIENT"
          ) {
            var temp = {
              unit: active_dosages_arr.measurement_type,
              from: active_dosages_arr.weight_from,
              to: active_dosages_arr.weight_to,
            };
            rangeArr.push(temp);
            allow_range_setting = active_dosages_arr.allow_min_max_setting;
          } else if (
            ingredient_selection_type ==
            "MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS"
          ) {
            var temp = {
              unit: active_dosages_arr.unit,
              from: active_dosages_arr.weight_from,
              to: active_dosages_arr.weight_to,
            };
            rangeArr.push(temp);
            allow_range_setting = active_dosages_arr.allow_min_max_setting;
          } else if (
            ingredient_selection_type ==
            "MULTIPLE_INGREDIENT_TYPES_MULTIPLE_MEASUREMENT_TYPES_PER_INGREDIENT"
          ) {
            if (
              data.allow_multiple_measurement_types_in_same_formula == false
            ) {
              var perUnit = active_dosages_arr.extra
                ? active_dosages_arr.extra
                : [];

              if (perUnit && Object.values(perUnit).length != 0) {
                Object.values(perUnit).map((item) => {
                  var temp = {
                    unit: item.measurement_type,
                    from: item.weight_from,
                    to: item.weight_to,
                  };
                  rangeArr.push(temp);
                });
              }
              allow_range_setting = active_dosages_arr.allow_min_max_setting;
            } else if (
              data.allow_multiple_measurement_types_in_same_formula == true
            ) {
              var temp = {
                unit: active_dosages_arr.measurement_type,
                from: active_dosages_arr.weight_from,
                to: active_dosages_arr.weight_to,
              };
              rangeArr.push(temp);
              allow_range_setting = active_dosages_arr.allow_min_max_setting;
            }
          }
        }
      } else if (
        active_dosages_arr &&
        active_dosages_arr.id &&
        active_dosages != ""
      ) {
        bundle_name = capitalizeWords(active_dosages);
        if (
          ingredient_selection_type ==
            "ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE" ||
          ingredient_selection_type ==
            "MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS"
        ) {
          var temp = {
            unit: active_dosages_arr.unit,
            from: active_dosages_arr.weight_from,
            to: active_dosages_arr.weight_to,
          };
          rangeArr.push(temp);
          allow_range_setting = 1;
        }
      }
    }
    if (
      rangeArr &&
      rangeArr.length != 0 &&
      IngredientWeight &&
      Object.values(IngredientWeight).length != 0
    ) {
      Object.values(IngredientWeight).map((item) => {
        var unit = item.unit;
        var qty = Number(item.qty);
        var findRange = rangeArr.find((type) => type.unit == unit);
        if (
          (ingredient_selection_type ==
            "ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE" ||
            ingredient_selection_type ==
              "MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS") &&
          rangeArr.length == 1
        ) {
          var findRange = rangeArr && rangeArr[0] ? rangeArr[0] : [];
          if (findRange) {
            if (allow_range_setting == true) {
              if (
                (qty >= Number(findRange.from) &&
                  qty <= Number(findRange.to)) == false
              ) {
                error.push({
                  // msg: `Selected ${bundle_name} allows ${findRange.from}${item.unit_name} to ${findRange.to}${item.unit_name} `,
                  msg: `The Total Quantity Received for the formula must be between ${findRange.from} ${item.unit_name} and ${findRange.to} ${item.unit_name} for ${bundle_name}.`,
                });
              }
            }
          }
        } else {
          if (findRange) {
            if (allow_range_setting == true) {
              if (
                (qty >= Number(findRange.from) &&
                  qty <= Number(findRange.to)) == false
              ) {
                error.push({
                  // msg: `Selected ${bundle_name} allows ${findRange.from}${item.unit_name} to ${findRange.to}${item.unit_name}  `,
                  msg: `The Total Quantity Received for the formula must be between ${findRange.from} ${item.unit_name} and ${findRange.to} ${item.unit_name} for ${bundle_name}.`,
                });
              }
            }
          }
        }
      });
    }
    return error;
  };
  const UpdateFormulaBuilderSetting = () => {
    if (data) {
      const prac_formula_setting =
        pracDetails && pracDetails.formula_setting
          ? JSON.parse(pracDetails.formula_setting)
          : [];
      const prac_setting =
        prac_formula_setting && prac_formula_setting[data.name]
          ? prac_formula_setting[data.name]
          : [];
      var requirements = [
        {
          column: "formula_bilder",
          name: "formula_bilder",
          is_required: 0,
          key: "formula_bilder",
          allow_zero: false,
        },
        {
          column: "prac_setting",
          name: "prac_setting",
          is_required: 0,
          key: "prac_setting",
          allow_zero: false,
        },
      ];
      var formValue = {
        formula_bilder: data,
        prac_setting: prac_setting,
      };
      var Final_array = [];
      Object.values(requirements).map((i, key) => {
        var temp = {
          ...i,
          error: "",
          value: formValue[i.key],
        };
        Final_array.push(temp);
      });
      handleSectionFormData(Final_array, "FORMULAS_SETTING");
    }
  };
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const CheckFormulaIngredientPrice = async (array) => {
    var error = 0;
    if (array.length != 0) {
      array.map((item) => {
        if (item.ingredient_id != "") {
          var price = item.formula_dosage_column_subtotal
            ? Number(item.formula_dosage_column_subtotal)
            : 0;
          if (!price || price == 0 || isNaN(price)) {
            error = 1;
          }
        }
      });
    }
    if (error == 0) {
      return true;
    }
    return false;
  };
  const unsetPrevDosagesType = () => {
    setPrevFormulaBuilderDosageMode(null);
    setPrevFormulaBuilderDosageModeKey(null);
  };
  const checkOutOfStockIngredients = async (data) => {
    const formData = await getFormData();
    // Trigger the popup for out of stock ingredients
    if (data.outofstock == "1") {
      const outOfStock = formData?.ingredients.filter(
        (ingredient) =>
          ingredient.is_outofstock &&
          ingredient.allow_to_purchase_outofstock == "0"
      );
      if (outOfStock.length > 0) {
        setOutOfStockIngredients(
          outOfStock.map((ingredient) => ingredient.extra_data.name)
        );
        setOutOfStockPopup(true);
        return false;
      }
    }
    return true;
  };
  const handleSaveFormula = async (type = "") => {
    setAllowSubmitBtn(true);
    var status;
    if (type === "convert") {
      status = await handleSubmit(true);
    } else {
      status = await handleSubmit();
    }

    if (status) {
      let url;
      switch (type) {
        case "convert":
          url = `${BASEURL}?page=formula_new&builder_id=${formulaConvertingTo}&convet_to=${formulaId}&section=new`;
          break;
        case "order":
          url = `${BASEURL}?page=formula_new&section=order&id=${formulaId}`;
          break;
        case "save":
          url = `${BASEURL}?page=formula-review-new&id=${formulaId}`;
          break;
        case "cart":
          const isValid = await checkOutOfStockIngredients(data);
          if (!isValid) {
            return;
          }
          url = `${BASEURL}?page=formula_new&section=addtocart&id=${formulaId}`;
          break;
        case "exit":
          url = `${BASEURL}?page=formula_new&section=list`;
          break;
        case "continue_editing":
          setAllowSubmitBtn(false);
          break;
        case "save_order":
          if (is_order === "true") {
            url = `${BASEURL}admin/control.php?Page=order&action=editorder&section=editorder&oid=${formulaDeatils?.order_id}`;
          }
          break;
        default:
          if (is_order === "true") {
            url = `${BASEURL}admin/control.php?Page=order&action=editorder&section=editorder&oid=${formulaDeatils?.order_id}`;
          } else {
            url = `${BASEURL}?page=formula-review-new&id=${formulaId}`;
          }
      }
      if (url && type != "continue_editing") {
        openURL(url, "_parent");
      }
    } else {
      setAllowSubmitBtn(false);
    }
  };

  useEffect(() => {
    const finalText = [];
    if (data && data.length !== 0) {
      /** Text box for all section */
      const textes = data?.formula_text;
      if (textes && textes.length !== 0) {
        textes.forEach((item) => {
          finalText[item.key_value] = item; // Assuming `item.value` should be used
        });
      }

      /** Label setting for all section */
      var labelData = data?.extra_styling ? JSON.parse(data.extra_styling) : {};
      const value = labelData["FORMULA_BUILDER_LABEL_STYLING"];

      // Use the reusable utility functions
      var labelSettings = getLabelSettings(value);
      var inputSettings = getInputSettings(value);
      var marginSettings = getMarginSettings(value);
      var borderSettings = getBorderSettings(value);
      var dropdownStyles = getDropdownStyles(value);
      var paddingSettings = getPaddingSettings(value);
      var bgStyle = getbgStyle(value);
      var width = value?.width ? `${value.width}px` : "350px";

      /** Tabel setting */
      const tableValue = labelData["FORMULA_BUILDER_TABEL_STYLING"];
      var tabelSettings = getTabelSettings(tableValue);
      var tabelMarginSettings = getTableMarginSetting(tableValue);
      var tableHeaderSetting = getTableHeaderSetting(tableValue)
    }

    setFormulaLabelSetting({
      labelSettings,
      inputSettings,
      marginSettings,
      borderSettings,
      dropdownStyles,
      paddingSettings,
      bgStyle,
      width,tabelSettings,tabelMarginSettings,tabelMarginSettings,tableHeaderSetting
    });
    setformulaTextBox(finalText);
  }, [data]);

  //##########################################
  if (formulaError == true && formulaErrorMsg != null && formulaId == "") {
    var errortext = (
      <>
        <Icon
          as={FaExclamationTriangle}
          size="2em"
          style={{ color: "red", marginTop: "-0.5rem" }}
        />
        <span
          style={{
            color: "red",
            marginLeft: "0.5rem",
            marginTop: "-0.5rem",
            fontSize: "1.5rem",
          }}
        >
          {formulaErrorMsg}
        </span>
      </>
    );
    return (
      <div>
        <Placeholder.Graph active />
        <Loader
          size="md"
          backdrop
          speed="slow"
          vertical
          center
          content={errortext}
        />
      </div>
    );
  }
  if (
    pracId == null ||
    pracId == "" ||
    pracId == undefined ||
    validPrac == false
  ) {
    var errortext = (
      <>
        <Icon
          as={FaExclamationTriangle}
          size="2em"
          style={{ color: "red", marginTop: "-0.5rem" }}
        />
        <span
          style={{
            color: "red",
            marginLeft: "0.5rem",
            marginTop: "-0.5rem",
            fontSize: "1.5rem",
          }}
        >
          Practitioner is missing.
        </span>
      </>
    );

    return (
      <div>
        <Placeholder.Graph active />
        <Loader
          size="md"
          backdrop
          speed="slow"
          vertical
          center
          content="loading..."
        />
      </div>
    );
  }
  if (
    formulaBuilderId == null ||
    formulaBuilderId == "" ||
    formulaBuilderId == undefined
  ) {
    var errortext = (
      <>
        <Icon as={FaExclamationTriangle} size="2em" style={{ color: "red" }} />
        <span
          style={{ color: "red", marginLeft: "0.5rem", fontSize: "1.5rem" }}
        >
          Formula Builder is missing.
        </span>
      </>
    );

    return (
      <div>
        <Placeholder.Graph active />
        <Loader
          size="md"
          backdrop
          speed="slow"
          vertical
          center
          content={errortext}
        />
      </div>
    );
  }
  if (loading || data == null) {
    return (
      <div>
        <Placeholder.Paragraph rows={19} />
        <Loader
          size="md"
          backdrop
          speed="slow"
          vertical
          center
          content="loading..."
        />
      </div>
    );
  }

  if (data && data != null) {
    var mainDivStyle = { marginBottom: "1rem" };
    var titleStyle = {};
    const font = data.text_font;
    if (font) {
      const fontUrl = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(
        font
      )}`;
      // Add font to head
      if (!document.querySelector(`link[href="${fontUrl}"]`)) {
        const linkElement = document.createElement("link");
        linkElement.rel = "stylesheet";
        linkElement.href = fontUrl;
        document.head.appendChild(linkElement);
      }
    }
  } else {
    var mainDivStyle = { marginBottom: "1rem" };
    var titleStyle = {};
  }
  const StyledDisplayName = ({ text, sectionStyle }) => {
    return <span style={sectionStyle}>{text}</span>;
  };

  return (
    <div className="section__content section__content--p31 formula-builder-main">
      <div
        className="FormulaBuilder container-fluid"
        data-testid="FormulaBuilder"
        style={{
          ...mainDivStyle,
          ...fontStyle,
        }}
      >
        <form>
          <h5 class="formula-title" style={titleStyle}>
            <b>
              {is_order ? "Edit Order " : "Create a New"} {data?.display_name}{" "}
              Formula
            </b>
          </h5>
          <FormulaSectionTextBox
            data={formulaTextBox["ABOVE_FORMULA_CONTENT"]}
            dosageFormulaKey=""
          />
          {Object.entries(formulaSections).length !== 0 &&
            Object.entries(formulaSections).map(([key, value], index) => {
              const dynamicTitleStyles = {
                fontSize: value?.font_size ? `${value.font_size}px` : "30px",
                color: value?.font_color || "black",
                textDecoration: value?.underline == "1" ? "underline" : "none",
                fontWeight: value?.bold == "1" ? "bold" : "normal",
                backgroundColor: value?.section_bg_color,
                marginTop: value?.title_margin_top
                  ? `${value.title_margin_top}px`
                  : "0px",
                marginRight: value?.title_margin_right
                  ? `${value.title_margin_right}px`
                  : "0px",
                marginLeft: value?.title_margin_left
                  ? `${value.title_margin_left}px`
                  : "0px",
                marginBottom: value?.title_margin_bottom
                  ? `${value.title_margin_bottom}px`
                  : "0px",
                paddingTop: value?.title_padding_top
                  ? `${value.title_padding_top}px`
                  : "0px",
                paddingRight: value?.title_padding_right
                  ? `${value.title_padding_right}px`
                  : "0px",
                paddingLeft: value?.title_padding_left
                  ? `${value.title_padding_left}px`
                  : "0px",
                paddingBottom: value?.title_padding_bottom
                  ? `${value.title_padding_bottom}px`
                  : "0px",
                display: "inline-block",
              };
              const titleDisplayName = value.display_name;
              const displayName = (
                <StyledDisplayName
                  text={titleDisplayName}
                  sectionStyle={{
                    ...dynamicTitleStyles,
                    fontFamily: data?.text_font ? data.text_font : "inherit",
                  }}
                />
              );
              if (value.is_active == 1) {
                if (value.key_value == "FORMULAS_GENERAL_DETAILS") {
                  return (
                    <FormulaBuilderGeneralSection
                      titleDisplayName={titleDisplayName}
                      display_name={displayName}
                      onGeneralSectionChange={handleGeneralSectionChange}
                      formulaBuilderId={formulaBuilderId}
                      data={data}
                      numberDays={numberDays}
                      pracDetails={pracDetails}
                      formulaList={formulaList}
                      dosageSettings={dosageSettings} // all Dosages types
                      onNumberDaysChange={handleNumberDaysChange}
                      formulaDeatils={formulaDeatils} // important
                      DosageMode={formulaBuilderDosageMode}
                      ApplyFormValidation={ApplyFormValidation}
                      thisFormData={handleSectionFormData}
                      dosages_converting={setDosageConverting}
                      formula_converting={setFormulaConverting}
                      is_order={is_order}
                      formulaTextBox={formulaTextBox}
                    />
                  );
                } else if (value.key_value == "FORMULAS_OPTIONS") {
                  return (
                    <>
                      <FormulaBuilderOptionsSection
                        titleDisplayName={titleDisplayName}
                        display_name={displayName}
                        formulaBuilderId={formulaBuilderId}
                        formulaDosagesType={formulaBuilderDosageModeType}
                        data={data}
                        numberDays={numberDays}
                        onNumberDaysChange={handleNumberDaysChange}
                        onNumberBagsChange={handleNumberBagsChange}
                        onOptionsSectionChange={handleOptionSectionChanges}
                        activeDosages={activeDosages}
                        pracDetails={pracDetails}
                        onActiveDosageDaysChange={handleActiveDosageChange}
                        formulaDosagesSetting={formulaDosagesSetting}
                        formulaDeatils={formulaDeatils} // important
                        updateDefaultMeasuremt={updateDefaultMeasuremt}
                        updateIngredientSelection={handleIngredientSelection}
                        ingredients={ingredients}
                        pracId={pracId}
                        formulaOptionCheck={handleFormulaOption}
                        formulaTotalQty={handletotalQty}
                        setConcentrates={setConcentrates}
                        activeOptionDosagesData={handleActiveOptionDosagesData}
                        ApplyFormValidation={ApplyFormValidation}
                        thisFormData={handleSectionFormData}
                        dosageConverting={dosageConverting}
                        formulaConverting={formulaConverting}
                        dosageId={formulaBuilderDosageMode}
                        fontFamily={{ ...fontStyle }}
                        returnSeletedIngredientType={setSeletedIngredientType}
                        formulaTextBox={formulaTextBox}
                        dosageKey={formulaBuilderDosageModeKey}
                        dosagesInputQty={DosagesTotalQty}
                      />
                    </>
                  );
                } else if (value.key_value == "FORMULAS_DOSAGES") {
                  return (
                    <>
                      <FormulaBuilderDosagesSection
                        titleDisplayName={titleDisplayName}
                        display_name={displayName}
                        formulaBuilderId={formulaBuilderId}
                        formulaDosagesType={formulaBuilderDosageModeType}
                        data={data}
                        dosageSettings={dosageSettings} // all Dosages modes data
                        activeDosages={activeDosages}
                        numberDays={numberDays}
                        onNumberDaysChange={handleNumberDaysChange}
                        formulaDosagesSetting={formulaDosagesSetting} // selected dosages mode data
                        onchange={HandleDosagesSectionChanges}
                        formulaDeatils={formulaDeatils} // important
                        dosageKey={formulaBuilderDosageModeKey}
                        measurementOptions={measurementOptions}
                        ApplyFormValidation={ApplyFormValidation}
                        thisFormData={handleSectionFormData}
                        pracDetails={pracDetails}
                        measurmentConversions={measurmentConversions}
                        ingredient_selection_type={
                          data.ingredient_selection_type
                        }
                        dosageConverting={dosageConverting}
                        formulaConverting={formulaConverting}
                        ingredients={ingredients}
                        dosageId={formulaBuilderDosageMode}
                        allow_multiple_measurements={
                          allow_multiple_measurements
                        }
                        ingredient_selection={ingredient_selection}
                        formulaTextBox={formulaTextBox}
                        formulaLabelSetting={formulaLabelSetting}
                        onFormulaWeightChange={handleDosagesInputQtyChange}
                      />
                    </>
                  );
                } else if (value.key_value == "FORMULAS_FORMULA_INGREDIENTS") {
                  const prac_formula_setting =
                    pracDetails && pracDetails.formula_setting
                      ? JSON.parse(pracDetails.formula_setting)
                      : [];
                  const prac_setting =
                    prac_formula_setting && prac_formula_setting[data.name]
                      ? prac_formula_setting[data.name]
                      : [];

                  var converting = [];
                  const prev_converting = [];
                  return (
                    <>
                      <FormulaBuilderIngredientsSection
                        titleDisplayName={titleDisplayName}
                        display_name={displayName}
                        formulaBuilderId={formulaBuilderId}
                        formulaData={data}
                        initialData={ingredients}
                        formulaIngredients={formulaIngredients}
                        dosagesBags={numberBags}
                        dosagesDays={numberDays}
                        formulaDosagesSetting={formulaDosagesSetting}
                        ingrediuentsTags={ingrediuentsTags}
                        currency={currency}
                        pracId={pracId}
                        prac_setting={prac_setting}
                        dacimalPlace={2} // default set manual
                        dosages_key={formulaDosagesSetting?.value}
                        seperator={seperator}
                        formulaIngredientsIdArr={formulaIngredientsIdArr}
                        nameTypes={nameTypes}
                        converting={converting}
                        prev_converting={prev_converting}
                        prevBuilderKey={data.name}
                        defaultMeasuremt={defaultMeasuremt}
                        defaultMeasuremtName={defaultMeasuremtName}
                        measurementOptions={measurementOptions}
                        allow_multiple_measurements={
                          allow_multiple_measurements
                        }
                        ingredient_selection_type={
                          data.ingredient_selection_type
                        }
                        measurment_type_list={data.measurment_type_list}
                        formulaDosagesType={formulaBuilderDosageModeType}
                        formulaDosagesKey={formulaBuilderDosageModeKey}
                        formulaDeatils={formulaDeatils} // important
                        setItemData={handleIngredientSectionChanges}
                        patient_id={formulaBuilderPatient}
                        allow_ingredients_type={ingredient_selection}
                        ingredient_decimal={ingredient_decimal}
                        measurmentConversions={measurmentConversions}
                        summaryData={handleSummaryData}
                        formulaOption={formulaOptionCheck}
                        activeOptionDosagesData={handleActiveOptionDosagesData}
                        ApplyFormValidation={ApplyFormValidation}
                        thisFormData={handleSectionFormData}
                        setConcentrates={setConcentrates}
                        formulaTotalQty={handletotalQty}
                        concentrates={concentrates}
                        totalQty={formulaTotalQty}
                        loadingTable={loadingTable}
                        dosageConverting={dosageConverting}
                        formulaConverting={formulaConverting}
                        prevDosageKey={prevFormulaBuilderDosageModeKey}
                        updateprevDosageKey={unsetPrevDosagesType}
                        formulaTextBox={formulaTextBox}
                      />
                    </>
                  );
                } else if (value.key_value == "FORMULAS_PREPARATION_INSTRUCTIONS") {
                  return (
                    <>
                      <FormulaBuilderWithoutTagPreprationInstructionsSection
                        titleDisplayName={titleDisplayName}
                        formulaBuilderId={formulaBuilderId}
                        data={data}
                        display_name={displayName}
                        ApplyFormValidation={ApplyFormValidation}
                        onChange={
                          HandleBlankPreprationInstructionsSectionChanges
                        }
                        formulaDeatils={formulaDeatils} // important
                        thisFormData={handleSectionFormData}
                        fontFamily={{ ...fontStyle }}
                        formulaTextBox={formulaTextBox}
                      />
                      <FormulaBuilderPreprationInstructionsSection
                        titleDisplayName={titleDisplayName}
                        formulaBuilderId={formulaBuilderId}
                        data={data}
                        currency={currency}
                        ingrediuentsTags={ingrediuentsTags}
                        ingredients={ingredients}
                        onChange={HandlePreprationInstructionsSectionChanges}
                        formulaDeatils={formulaDeatils}
                        index={index}
                        display_name={displayName}
                        ApplyFormValidation={ApplyFormValidation}
                        thisFormData={handleSectionFormData}
                      />
                    </>
                  );
                } else if (value.key_value == "FORMULAS_ADD_ON_SERVICES") {
                  return (
                    <FormulaBuilderServicesSection
                      titleDisplayName={titleDisplayName}
                      formulaBuilderId={formulaBuilderId}
                      data={data}
                      currency={currency}
                      display_name={displayName}
                      onServicesSectionChange={handleServiesSectionChanges}
                      formulaDeatils={formulaDeatils} // important
                      ApplyFormValidation={ApplyFormValidation}
                      thisFormData={handleSectionFormData} //Done
                      selectedAddOnServices={handleAddOnServicesPrice}
                      price={price}
                      formulaTextBox={formulaTextBox}
                    />
                  );
                } else if (value.key_value == "FORMULAS_REFILLS") {
                  return (
                    <FormulaBuilderRefillSection
                      titleDisplayName={titleDisplayName}
                      onRefillSectionChange={handleRefillSectionChange}
                      formulaBuilderId={formulaBuilderId}
                      data={data}
                      pracDetails={pracDetails}
                      display_name={displayName}
                      formulaDeatils={formulaDeatils} // important
                      ApplyFormValidation={ApplyFormValidation}
                      thisFormData={handleSectionFormData} //Done
                      formulaTextBox={formulaTextBox}
                      formulaLabelSetting={formulaLabelSetting}
                    />
                  );
                } else if (value.key_value == "FORMULAS_MARKUP") {
                  return (
                    <FormulaBuilderMarkupSection
                      titleDisplayName={titleDisplayName}
                      formulaBuilderId={formulaBuilderId}
                      data={data}
                      price={price}
                      currency={currency}
                      onMarkupSectionChange={handleMarkupSectionChanges}
                      pracDetails={pracDetails}
                      display_name={displayName}
                      formulaDeatils={formulaDeatils} // important
                      ApplyFormValidation={ApplyFormValidation}
                      thisFormData={handleSectionFormData} //Done
                      formulaTextBox={formulaTextBox}
                      formulaLabelSetting={formulaLabelSetting}
                    />
                  );
                } else if (value.key_value == "FORMULAS_PRICE") {
                  return (
                    <FormulaBuilderPriceSection
                      titleDisplayName={titleDisplayName}
                      formulaBuilderId={formulaBuilderId}
                      data={data}
                      price={price}
                      currency={currency}
                      allowMarkup={allowMarkup}
                      markup_type={markup_type}
                      markup_amount={markup_amount}
                      addOnServices={addOnServices}
                      ingredients={ingredients}
                      priceArr={priceArr}
                      display_name={displayName}
                      onChange={handlePriceSectionChanges}
                      formulaDosagesSetting={formulaDosagesSetting}
                      numberBags={numberBags}
                      activeOptionDosagesData={activeOptionDosagesData}
                      formulaDosagesType={formulaBuilderDosageModeType}
                      activeDosages={activeDosages}
                      ApplyFormValidation={ApplyFormValidation}
                      thisFormData={handleSectionFormData} //Done
                      formulaDeatils={formulaDeatils} // important
                      formulaTextBox={formulaTextBox}
                      formulaLabelSetting={formulaLabelSetting}
                    />
                  );
                } else if (value.key_value == "FORMULAS_SUMMARY") {
                  return (
                    <FormulaBuilderSummarySection
                      titleDisplayName={titleDisplayName}
                      formulaBuilderId={formulaBuilderId}
                      data={data}
                      price={price}
                      currency={currency}
                      formulaSummary={formulaSummaryData}
                      ApplyFormValidation={ApplyFormValidation}
                      thisFormData={handleSectionFormData} //Done
                      display_name={displayName}
                      allowRawQty={AllowRawQty}
                      allowEnterQty={AllowEnterQty}
                      ingredients={ingredients}
                      formulaTextBox={formulaTextBox}
                      formulaLabelSetting={formulaLabelSetting}
                    />
                  );
                } else if (value.key_value == "FORMULAS_DISPENSARY_INSTRUCTIONS") {
                  return (
                    <FormulaBuilderDispensaryInstructions
                      titleDisplayName={titleDisplayName}
                      formulaBuilderId={formulaBuilderId}
                      data={data}
                      display_name={displayName}
                      formulaDeatils={formulaDeatils}
                      ApplyFormValidation={ApplyFormValidation}
                      onInsUpdate={handleDispensaryInstructions}
                      thisFormData={handleSectionFormData}
                      formulaTextBox={formulaTextBox}
                    />
                  );
                }
              }
            })}
          {Object.entries(formulaSections).length !== 0 && (
            <>
              <br></br>
              <FormulaSectionTextBox
                data={formulaTextBox["FORMULA_CONTENT_BELOW"]}
                dosageFormulaKey=""
              />
              <FormulaSaveButton
                formulaId={formulaId}
                onchnage={handleSaveFormula}
                pracDetails={pracDetails}
                formulaDetail={data}
                is_order={is_order}
                allowSubmitBtn={allowSubmitBtn}
              />
            </>
          )}
          {/* Show the popup only if required  #outOfStock*/}
          {showOutOfStockPopup && (
            <IngredientPopup
              outOfStockIngredients={outOfStockIngredients}
              data={data}
              dropdownStyle={fontStyle}
              onClose={() => {
                setOutOfStockPopup(false);
                setAllowSubmitBtn(false);
              }}
              onEditFormula={() => {
                setOutOfStockPopup(false);
                setAllowSubmitBtn(false);
              }}
            />
          )}

          {/* Show the popup only if required #bundleWeight/Volumn [Capsuls, softgels tablets, baseingredients]*/}
          {showBundlePopup && (
            <BundlePopup
              message={bundleErrMsg}
              onClose={() => {
                setBundlePopup(false);
              }}
            />
          )}
        </form>
        {/* <pre>{JSON.stringify(seletedIngredientType, null, 2)}</pre> */}
      </div>
    </div>
  );
};
FormulaBuilder.propTypes = {};
FormulaBuilder.defaultProps = {};

export default FormulaBuilder;
