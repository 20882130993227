import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { percentageRequest } from "./percentageIssue/percentageIssue.action";
import { InputNumber, DatePicker, Form } from "rsuite";
import FormulaSectionTextBox from "../helpers/TextBox";
import { colors } from "@mui/material";
import { blue } from "@mui/material/colors";
const JSONView = ({ formValue, formError }) => (
  <div className="row" style={{ marginBottom: 10 }}>
    <div class="col-8 json-tree-wrapper rs-panel">
      <div aria-expanded="false" class="rs-panel-header" tabindex="-1">
        <p class="rs-panel-title">formValue</p>
      </div>
      <div role="region" class="rs-panel-body">
        <pre>{JSON.stringify(formValue, null, 2)}</pre>
      </div>
    </div>
    <div class="col-4 json-tree-wrapper rs-panel">
      <div aria-expanded="false" class="rs-panel-header" tabindex="-1">
        <p class="rs-panel-title">formError</p>
      </div>
      <div role="region" class="rs-panel-body">
        <pre>{JSON.stringify(formError, null, 2)}</pre>
      </div>
    </div>
  </div>
);
const ErrorMessage = ({ children }) => (
  <>
    <Form.HelpText style={{ color: "red" }}>{children}</Form.HelpText>
    {/* <span style={{ color: 'red' }}>{children}</span> */}
  </>
);

const FormulaBuilderPriceSection = ({
  formulaBuilderId,
  currency,
  price,
  allowMarkup = 0,
  markup_type,
  markup_amount,
  addOnServices,
  onChange,
  ingredients,
  priceArr,
  display_name,
  data,
  formulaDosagesSetting,
  numberBags,
  activeDosages = null,
  formulaDosagesType,
  activeOptionDosagesData,
  ApplyFormValidation,
  thisFormData,
  formulaDeatils,
  formulaTextBox,
  formulaLabelSetting
}) => {
 
  const dispatch = useDispatch();
  const [totalPrice, setTotalPrice] = useState(0);
  // const [ingredientTotal, setIngredientsTotal] = useState(price);
  const [ingredientTotal, setIngredientsTotal] = useState(() =>
    parseFloat(price).toFixed(2)
  );
  const [markupTotal, setMarkupTotal] = useState(0);
  const [addOnServiesTotal, setAddOnServiesTotal] = useState(0);
  const [subtotalPrice, setSubTotalPrice] = useState(0);
  const [max_markup, setmax_markup] = useState(
    parseFloat(data.max_markup) || 0
  );
  const [allow_max_markup, setallowMarkup] = useState(
    parseFloat(data.allowed_max_markup_price) || 0
  );
  const [calc_makrup, setCalcMarkup] = useState(0);

  const [ingredientArr, setIngredientArr] = useState([]);
  const [IngredientCount, setIngredientCount] = useState(0);
  const [IngredientWeight, setIngredientWeight] = useState(0);
    const [IngredientWeightPerUnit, setIngredientWeightPerUnit] = useState([]);
    const [IngredientWeightVolumeArr,setIngredientWeightVolumeArr] = useState([]);
  const [fees, setFess] = useState([]);
  const [appliedFees, setAppliedFees] = useState([]);

  const [feesTotal, setFeesTotal] = useState(0);
  const [capsulefeesTotal, setcapsulefeesTotal] = useState(0);
  const [allowed_fees, set_alloed_fees] = useState([]);

  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({});
  var requirements = [
    {
      column: "ingredient_price",
      name: "Ingredient Price",
      is_required: 1,
      key: "ingredient_price",
      allow_zero: false,
    },
    {
      column: "addon_services_price",
      name: "Add On Service",
      is_required: 0,
      key: "addon_services_price",
      allow_zero: false,
    },
    {
      column: "markup_price",
      name: "Markup Price",
      is_required: 0,
      key: "markup_price",
      allow_zero: false,
    },
    {
      column: "fees",
      name: "Fees Price",
      is_required: 0,
      key: "fees",
      allow_zero: false,
    },
    {
      column: "capsules_fees",
      name: "Capsules Fees Price",
      is_required: 0,
      key: "capsules_fees",
      allow_zero: false,
    },
    {
      column: "allowed_fees",
      name: "Allowed Fees",
      is_required: 0,
      key: "allowed_fees",
      allow_zero: false,
    },
  ];

  var servicesDataArr = data.addOnServices
    ? JSON.parse(data.addOnServices)
    : [];
  const servicesData = servicesDataArr.flatMap((item) => {
    const key = Object.keys(item)[0];
    return [{ ...item[key], category: key }];
  });

  useEffect(() => {
    var fes = data && data.fees != "" && data.fees != null ? data.fees : [];
    setFess(fes);
  }, [formulaBuilderId, data, formulaDeatils]);

  useEffect(() => {
    const filteredIngredients = ingredients.filter((ingredient) => {
      return (
        ingredient.ingredient_id != null &&
        ingredient.ingredient_id != "" &&
        ingredient.ingredient_id != undefined
      );
    });
    const totalQtyReceived = filteredIngredients.reduce(
      (accumulator, ingredient) => {
        // Convert formula_dosage_column_qty_received to a number using parseFloat
        const qtyReceived = parseFloat(
          ingredient.formula_dosage_column_qty_received
        );
        // Check if qtyReceived is a valid number
        if (!isNaN(qtyReceived)) {
          // Add the valid qtyReceived to the accumulator
          return accumulator + qtyReceived;
        }
        // If formula_dosage_column_qty_received is not a valid number, return the current accumulator
        return accumulator;
      },
      0
    );
    setIngredientCount(filteredIngredients.length);
    setIngredientWeight(totalQtyReceived);
    setIngredientArr(filteredIngredients);

    // Ingredient Weight Per Unit
    const groupedQuantities = filteredIngredients.reduce((accumulator, ingredient) => {
      const measurement = ingredient?.default_measurment; // Grouping key
      const qtyReceived = parseFloat(ingredient.formula_dosage_column_qty_received);
    
      // Ensure qtyReceived is a valid number
      if (!isNaN(qtyReceived) && measurement) {
        // If the measurement group already exists, add to its total
        if (accumulator[measurement]) {
          accumulator[measurement] += qtyReceived;
        } else {
          // Initialize the group with the current qtyReceived
          accumulator[measurement] = qtyReceived;
        }
      }
      
      return accumulator;
    }, {});
    // 
    var groupedQuantitiesArray = [];
    var weightVolumnQty = []
    const measurment_type_list = data? data.measurment_type_list:[];
    if(data && data.measurment_type_list){
      
       groupedQuantitiesArray = Object.keys(groupedQuantities).length
      ? Object.entries(groupedQuantities).map(
          ([measurement, totalQty]) => {
            const measurementObj = measurment_type_list.find(
              (measurment) => measurment.id == measurement
            );
            var measurement_type= measurementObj ? measurementObj.type : ''
            if (weightVolumnQty[measurement]) {
              weightVolumnQty[measurement] += totalQty;
            } else {
              // Initialize the group with the current qtyReceived
              weightVolumnQty[measurement_type] = totalQty;
            }
            return {
              measurement:  measurement,
              measurement_type: measurement_type,
              measurement_array: measurementObj ? measurementObj : {},
              totalQty,
            };
          }
        )
      : [];
    }
    setIngredientWeightPerUnit(groupedQuantitiesArray);
    setIngredientWeightVolumeArr(weightVolumnQty);
    console.log('fee-weight',groupedQuantities,groupedQuantitiesArray,weightVolumnQty);

    if (fees.length != 0 && filteredIngredients.length != 0) {
    }
  }, [ingredients]);

  const calculate_markup = () => {
    let calcVal = 0;
    if (allow_max_markup && markupTotal > allow_max_markup) {
      calcVal = Number(markupTotal).toFixed(2);
    } else {
      calcVal = Number(markupTotal).toFixed(2);
    }

    setCalcMarkup(calcVal);
  };

  useEffect(() => {
    setAddOnServiesTotal(0);
    setSubTotalPrice(0);
    var addOnServicesAmount = 0;
    servicesData?.map((i, k) => {
      var thisId = String(i.id);
      if (addOnServices.includes(thisId)) {
        const addon_price = parseFloat(i?.price) || 0;
        const addon_tax = parseFloat(i?.tax) || 0;
        addOnServicesAmount = parseFloat(
          (
            addOnServicesAmount +
            (addon_price + (parseFloat(price) * addon_tax) / 100)
          ).toFixed(2)
        );
        // addOnServicesAmount =
        //   addOnServicesAmount +
        //   (addon_price + (parseFloat(price) * addon_tax) / 100);
      }
    });
    setAddOnServiesTotal(parseFloat(addOnServicesAmount.toFixed(2)));
    // setAddOnServiesTotal(addOnServicesAmount);

    // Fees
    var allow_fess = [];
    var ing_fees = 0;
    setFeesTotal(0);
    var ingredient_selection_type= data?.ingredient_selection_type
    if (fees.length != 0 && ingredientArr.length != 0) {
      //&& ingredientArr.length != 0
      fees.map((i, k) => {
        if (
          i.range_type == "weight_combined_herbs" &&
          IngredientCount >= Number(i.herbs_range_from) &&
          IngredientCount <= Number(i.herbs_range_to) 
          // && IngredientWeight >= Number(i.weight_range_from) &&
          // IngredientWeight <= Number(i.weight_range_to)
        ) {
          // ing_fees += Number(i.formula_fee);
          // allow_fess.push(i);
          if (
            ingredient_selection_type == "ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE" ||
            ingredient_selection_type == "MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS"
          ) {
            if (
              IngredientWeight >= Number(i.weight_range_from) &&
              IngredientWeight <= Number(i.weight_range_to)
            ) {
              ing_fees += Number(i.formula_fee);
              allow_fess.push(i);
            }
          } else {
            
            if (
              IngredientWeightPerUnit &&
              IngredientWeightPerUnit.length != 0              
              
            ) {
              const measurementObj = IngredientWeightPerUnit.find(
                (val) => val.measurement == i.measurement_type
              );console.log('fee-total_weight-checkUniT',i.measurement_type,measurementObj)
              if(measurementObj){
                const currentIngredientWeight = Number(measurementObj['totalQty']);
                console.log('fee-total_weight',i.measurement_type,currentIngredientWeight,i.weight_range_from,i.weight_range_to)
                if (
                  currentIngredientWeight >= Number(i.weight_range_from) &&
                  currentIngredientWeight <= Number(i.weight_range_to)
                ) {
                  ing_fees += Number(i.formula_fee);
                  allow_fess.push(i);
                }
              }
              
            }
          }
          
        }

        if (
          i.range_type == "no_of_herbs" &&
          IngredientCount >= Number(i.herbs_range_from) &&
          IngredientCount <= Number(i.herbs_range_to)
        ) {
          ing_fees += Number(i.formula_fee);
          allow_fess.push(i);
        }else{
          console.log('fee-no_of_herbs',i.range_type,IngredientCount,i.weight_range_from,i.weight_range_to)
        }

        if (
          i.range_type == "total_weight" 
        ) {
          
          if (
            ingredient_selection_type == "ONE_INGREDIENT_TYPE_ONE_MEASUREMENT_TYPE" ||
            ingredient_selection_type == "MULTIPLE_INGREDIENT_TYPES_ONE_MEASUREMENT_TYPE_FOR_ALL_INGREDIENTS"
          ) {
            if (
              IngredientWeight >= Number(i.weight_range_from) &&
              IngredientWeight <= Number(i.weight_range_to)
            ) {
              ing_fees += Number(i.formula_fee);
              allow_fess.push(i);
            }
          } else {
           
           
            if (
              IngredientWeightPerUnit &&
              IngredientWeightPerUnit.length != 0 
            ) {
              const measurementObj = IngredientWeightPerUnit.find(
                (val) => val.measurement == i.measurement_type
              );console.log('fee-total_weight-checkUniT',i.measurement_type,measurementObj)
              if(measurementObj){
                const currentIngredientWeight = Number(measurementObj['totalQty']);
                console.log('fee-total_weight',i.measurement_type,currentIngredientWeight,i.weight_range_from,i.weight_range_to)
                if (
                  currentIngredientWeight >= Number(i.weight_range_from) &&
                  currentIngredientWeight <= Number(i.weight_range_to)
                ) {
                  ing_fees += Number(i.formula_fee);
                  allow_fess.push(i);
                }
              }
              
            }
          }          
        }

        if (
          i.range_type == "no_of_containers" &&
          formulaDosagesSetting &&
          formulaDosagesSetting.no_of_container &&
          formulaDosagesSetting.no_of_container != 0 &&
          numberBags != 0 &&
          Number(numberBags) >= Number(i.herbs_range_from) &&
          Number(numberBags) <= Number(i.herbs_range_to)
        ) {
          ing_fees += Number(i.formula_fee);
          allow_fess.push(i);
        }
      });
      
    }
    setFeesTotal(ing_fees);
    set_alloed_fees(allow_fess);
    console.log('fee-applied',allow_fess,fees)

    var capsule_fees = 0;
    setcapsulefeesTotal(0);
    if (
      activeDosages != "" &&
      activeDosages != null &&
      activeDosages != undefined &&
      activeOptionDosagesData &&
      activeOptionDosagesData.length !== 0
    ) {
      const addon_price = parseFloat(activeOptionDosagesData.price) || 0;
      const addon_tax = parseFloat(activeOptionDosagesData.tax) || 0;
      capsule_fees = addon_price + (parseFloat(price) * addon_tax) / 100;
      setcapsulefeesTotal(capsule_fees);
    }

    const subtotal = parseFloat(price) + addOnServicesAmount;
    setSubTotalPrice(parseFloat(subtotal.toFixed(2)));
    // setSubTotalPrice(parseFloat(price) + addOnServicesAmount); //+ calculate_fees + capsule_fees
    var currentSubTotalPrice = parseFloat(subtotal.toFixed(2));
    if (allowMarkup == 1) {
      /////////// Markup
      markup_amount = parseFloat(markup_amount) || 0;
      if (markup_type == "flat") {
        markup_amount.toFixed(2);
        setMarkupTotal(markup_amount);
        //console.log('subtotalPrice',currentSubTotalPrice)
        var total =
          parseFloat(markup_amount) + parseFloat(currentSubTotalPrice);
        //setTotalPrice(total)
      } else if (markup_type == "percentage") {
        //console.log('subtotalPrice',currentSubTotalPrice)
        const finalPrice = parseFloat(currentSubTotalPrice) || 0;
        const markupPrice = parseFloat(markup_amount) || 0;
        const markupTemp = (finalPrice * markupPrice) / 100;
        const markuprice = markupTemp.toFixed(2);
        if (parseFloat(markuprice) === 0) {
          setMarkupTotal(markupPrice.toFixed(2));
        } else {
          setMarkupTotal(markuprice);
        }
        var total = parseFloat(markuprice) + parseFloat(currentSubTotalPrice);
        if (
          Number(markuprice) > Number(allow_max_markup) &&
          Number(allow_max_markup) != 0
        ) {
          let abc = Number((allow_max_markup / currentSubTotalPrice) * 100);
          if (allow_max_markup) {
            setTimeout(() => {
              dispatch(percentageRequest(abc.toFixed(2)));
            }, 100);
          }
        } else {
          setTimeout(() => {
            dispatch(percentageRequest(null));
          }, 100);
        }
      } else {
        setMarkupTotal(0);
        setTotalPrice(currentSubTotalPrice);
      }
    }
    formValidation();
    ////////////////////////////
  }, [
    markup_type,
    markup_amount,
    price,
    addOnServices,
    numberBags,
    formulaDosagesSetting,
    activeDosages,
    activeOptionDosagesData,
    allow_max_markup,
    ingredients,
    fees,
  ]);

  useEffect(() => {
    onChange(
      price,
      addOnServiesTotal,
      subtotalPrice,
      markupTotal,
      Number(
        Number(markupTotal) +
          Number(subtotalPrice) +
          Number(feesTotal) +
          Number(capsulefeesTotal)
      ).toFixed(2),
      feesTotal,
      capsulefeesTotal
    );
    formValidation();
  }, [
    ingredientTotal,
    addOnServiesTotal,
    subtotalPrice,
    markupTotal,
    totalPrice,
    feesTotal,
    capsulefeesTotal,
    ingredients,
  ]);

  useEffect(() => {
    if (ApplyFormValidation == true) {
      //formValidation()
    }
  }, [ApplyFormValidation]);

  const formValidation = () => {
    formValue["ingredient_price"] = price;
    formValue["addon_services_price"] = addOnServiesTotal;
    formValue["markup_price"] = markupTotal;
    formValue["fees"] = feesTotal;
    formValue["allowed_fees"] = allowed_fees;
    formValue["capsules_fees"] = capsulefeesTotal;

    var Final_array = [];
    Object.values(requirements).map((i, key) => {
      var temp = {
        ...i,
        error: formError[i.key],
        value: formValue[i.key],
      };
      Final_array.push(temp);
    });
    thisFormData(Final_array, "FORMULAS_PRICE");
  };

const tableData = formulaLabelSetting?.tabelSettings
  return (
    <>
      <div className="FormulaBuilderPriceSection">
        <h2 className="title mt-5">{display_name}</h2>
        <FormulaSectionTextBox data={formulaTextBox['PRICING_BELOW']} dosageFormulaKey ='' />
        <br />
        <div className="col-6">
          {/* <pre>{JSON.stringify(activeOptionDosagesData, null, 2)}</pre> */}
          <table className="table TableMainadd table-bordered" style={{...formulaLabelSetting.tabelMarginSettings, width:'85%'}}>
            <thead>
              <tr>
              <th className="col-2 col-form-label" 
              style={{...formulaLabelSetting.tableHeaderSetting}}
              >Name</th>
              <th className="col-2"  style={{...formulaLabelSetting.tableHeaderSetting}}>Price</th>
              </tr>
            </thead>
            <tbody className="tbody">
              <tr>
                <td className="col-form-label" style={{...tableData}}>Ingredients</td>
                <td style={{...tableData}}>
                  {currency}
                  {price?.toFixed(2)}
                </td>
              </tr>
              {servicesData?.map((i, k) => {
                var thisId = String(i.id);
                if (addOnServices.includes(thisId)) {
                  const addon_price = parseFloat(i?.price) || 0;
                  const addon_tax = parseFloat(i?.tax) || 0;
                  return (
                    <tr key={k}>
                      <td className="col-form-label" style={{...tableData}}>{i.name}</td>
                      <td style={{...tableData}}>
                        {currency}
                        {(addon_price + (price * addon_tax) / 100).toFixed(2)}
                      </td>
                    </tr>
                  );
                }
              })}

              {allowMarkup == 1 ? (
                <>
                  <tr>
                    <td className="col-form-label" style={{...tableData}}>Markup</td>
                    <td style={{...tableData}}
                      title={`${currency}${
                        Number(markupTotal).toFixed(2) || Number(0).toFixed(2)
                      }`}
                    >
                      {currency}
                      {allow_max_markup && markupTotal > allow_max_markup
                        ? Number(allow_max_markup).toFixed(2)
                        : Number(markupTotal).toFixed(2)}
                    </td>
                  </tr>
                </>
              ) : (
                ""
              )}

              {/* capsules,tablets,Softgel fees */}
              {activeDosages != "" &&
                activeDosages != null &&
                activeDosages != undefined &&
                activeOptionDosagesData &&
                activeOptionDosagesData.length !== 0 && (
                  <tr key={activeDosages + "fees"}>
                    <td className="col-form-label" style={{...tableData}}>
                      {activeOptionDosagesData.name
                        ? activeOptionDosagesData.name
                        : activeDosages}
                    </td>
                    <td style={{...tableData}}>
                      {(() => {
                        const addon_price =
                          parseFloat(activeOptionDosagesData.price) || 0;
                        const addon_tax =
                          parseFloat(activeOptionDosagesData.tax) || 0;
                        return (
                          <>
                            {currency}
                            {(addon_price + (price * addon_tax) / 100).toFixed(
                              2
                            )}
                          </>
                        );
                      })()}
                    </td>
                  </tr>
                )}

              {/* {IngredientCount + ' - ' + IngredientWeight} */}
              {fees.length != 0 &&
                ingredientArr.length != 0 &&
                allowed_fees.length != 0 &&
                allowed_fees.map((i, k) => {                  
                    return (
                      <tr key={k}>
                        <td className="col-form-label" style={{...tableData}}>
                          {/* Fees - */}
                          {i.fee_name ? i.fee_name : "Fees"}
                        </td>
                        <td style={{...tableData}}>
                          {currency}
                          {Number(i.formula_fee).toFixed(2)}
                        </td>
                      </tr>
                    );                  
                })}
              <tr>
                <td className="col-form-label" style={{...tableData}}>Total Price </td>
                <td className="font-weight" style={{...tableData}}>
                  {currency}

                  {allow_max_markup && markupTotal > allow_max_markup
                    ? Number(
                        allow_max_markup +
                          Number(subtotalPrice) +
                          Number(feesTotal) +
                          Number(capsulefeesTotal)
                      ).toFixed(2)
                    : (
                        Number(markupTotal) +
                        Number(subtotalPrice) +
                        Number(feesTotal) +
                        Number(capsulefeesTotal)
                      ).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-4">
          {/* <JSONView formValue={formValue} formError={formError} /> */}
        </div>
      </div>
    </>
  );
};

export default FormulaBuilderPriceSection;
