import React from "react";

const FormulaSectionTextBox = ({ data, dosageFormulaKey="" }) => {
  if (!data) return null; // Handle case when data is null or undefined

  const decodeHTML = (html) => {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    let decoded = txt.value;
  
    // Check if decoding again changes the string
    while (decoded !== html) {
      html = decoded;
      txt.innerHTML = html;
      decoded = txt.value;
    }
  
    return decoded;
  };
  const cleanHTML = (html) => {
    return html.replace(/<p>\s*<\/p>/g, "").trim();
  };


  const getDisplayContent = () => {
    if (data.common_text_content == "0" && dosageFormulaKey) {
      // Show content based on dosageKey if `dosage_mode` is "per_mode"
      switch (dosageFormulaKey) {
        case "FORMULA_DOSAGE_MODE_TOTAL_QTY":
          return data.exact_quantity_text_content || "";
        case "FORMULA_DOSAGE_MODE_DAILY":
          return data.daily_dose_text_content || "";
        case "FORMULA_DOSAGE_MODE_PER_BAG":
          return data.per_bag_text_content || "";
        default:
          return  ""; 
      }
    }

    // Show default content if `dosage_mode` is "all"
    if (data.common_text_content == "1" || data.dosage_mode == 'all') {
      return data.text_content || "";
    }
    return data.text_content || ""; 
  };

  const decodedContent = cleanHTML(decodeHTML(getDisplayContent()));

  // Style object for the TextBox
  const textBoxStyle = {
    width: data.width ? `${data.width}%` : "100%",
    border: 'solid',
    borderColor: data.border_color,
    borderWidth: data.border ? `${data.border}px` : "0px",
    marginTop: data.margin_top ? `${data.margin_top}px` : "0px",
    marginRight: data.margin_right ? `${data.margin_right}px` : "0px",
    marginLeft: data.margin_left ? `${data.margin_left}px` : "0px",
    marginBottom: data.margin_bottom ? `${data.margin_bottom}px` : "0px",
  };

  return decodedContent ? (
    <div style={textBoxStyle}>
      <div dangerouslySetInnerHTML={{ __html: decodedContent }} />
    </div>
  ) : null;
};

export default FormulaSectionTextBox;
