export const getLabelSettings = (value) => ({
  fontSize: value?.font_size ? `${value.font_size}px` : "12px",
  color: value?.font_color || "inherit",
  textDecoration: value?.label_underline === "1" ? "underline" : "none",
  fontWeight: value?.label_bold === "1" ? "bold" : "normal",
  backgroundColor: value?.font_highlight_color || "transparent",
  border: "solid",
  borderRadius: "6px",
  borderColor: value?.label_border_color || "defaultColor",
  borderWidth: value?.label_border_size
    ? `${value.label_border_size}px`
    : "0px",
  paddingTop:
    value && value["padding-top"] ? `${value["padding-top"]}px` : "0px",
  paddingRight:
    value && value["padding-right"] ? `${value["padding-right"]}px` : "0px",
  paddingLeft:
    value && value["padding-left"] ? `${value["padding-left"]}px` : "0px",
  paddingBottom:
    value && value["padding-bottom"] ? `${value["padding-bottom"]}px` : "0px",
});

export const getInputSettings = (value) => ({
  fontSize: value?.input_font_size ? `${value.input_font_size}px` : "12px",
  color: value?.input_font_color || "inherit",
});

export const getMarginSettings = (value) => ({
  marginTop: value && value["margin-top"] ? `${value["margin-top"]}px` : "0px",
  marginRight:
    value && value["margin-right"] ? `${value["margin-right"]}px` : "0px",
  marginLeft:
    value && value["margin-left"] ? `${value["margin-left"]}px` : "0px",
  marginBottom:
    value && value["margin-bottom"] ? `${value["margin-bottom"]}px` : "0px",
});

export const getBorderSettings = (value) => ({
  border: "solid",
  borderColor: value?.input_border_color || "defaultColor",
  borderWidth: value?.input_border_size
    ? `${value.input_border_size}px`
    : "1px",
  backgroundColor: value?.input_bg_color || "transparent",
});

export const getbgStyle = (value) => ({
  backgroundColor: value?.input_dp_bg_color || "transparent",
});

export const getDropdownStyles = (value) => ({
  backgroundColor: value?.input_dp_hover_color || "transparent",
  color: value?.input_dp_hover_text_color || "inherit",
});

export const getPaddingSettings = (value) => ({
  paddingTop: value?.data_padding_top
  ? `${value.data_padding_top}px`
  : "0px",
paddingRight: value?.data_padding_right
  ? `${value.data_padding_right}px`
  : "0px",
paddingLeft: value?.data_padding_left
  ? `${value.data_padding_left}px`
  : "0px",
paddingBottom: value?.data_padding_bottom
  ? `${value.data_padding_bottom}px`
  : "0px",
});

export const getTabelSettings = (tableValue) => ({
  fontSize: tableValue?.font_size ? `${tableValue.font_size}px` : "16px",
  color: tableValue?.font_color || "black",
  textDecoration: tableValue?.underline === "1" ? "underline" : "none",
  fontWeight: tableValue?.bold === "1" ? "bold" : "normal",
  backgroundColor: tableValue?.bg_color || "transparent",
  border: "solid",
  borderColor: tableValue?.border_color || "black",
  borderWidth: tableValue?.border_size ? `${tableValue.border_size}px` : "1px",
  paddingTop: tableValue?.padding_top ? `${tableValue.padding_top}px` : "0px",
  paddingRight: tableValue?.padding_right ? `${tableValue.padding_right}px` : "0px",
  paddingLeft: tableValue?.padding_left ? `${tableValue.padding_left}px` : "0px",
  paddingBottom: tableValue?.padding_bottom ? `${tableValue.padding_bottom}px` : "0px",
});

export const getTableMarginSetting =(tableValue)=>({
  marginTop: tableValue?.margin_top ? `${tableValue.margin_top}px` : "0px",
  marginRight: tableValue?.margin_right ? `${tableValue.margin_right}px` : "0px",
  marginLeft: tableValue?.margin_left ? `${tableValue.margin_left}px` : "0px",
  marginBottom: tableValue?.margin_bottom ? `${tableValue.margin_bottom}px` : "0px",
})

export const getTableHeaderSetting = (tableValue)=>({
  fontSize: tableValue?.th_font_size ? `${tableValue.th_font_size}px` : "16px",
  color: tableValue?.th_font_color || "black",
  textDecoration: tableValue?.th_underline === "1" ? "underline" : "none",
  fontWeight: tableValue?.th_bold === "1" ? "bold" : "normal",
  backgroundColor: tableValue?.th_bg_color || "transparent",
  border: "solid",
  borderColor: tableValue?.th_border_color || "black",
  borderWidth: tableValue?.th_border_size ? `${tableValue.th_border_size}px` : "1px",
  paddingTop: tableValue?.padding_top ? `${tableValue.padding_top}px` : "0px",
  paddingRight: tableValue?.padding_right ? `${tableValue.padding_right}px` : "0px",
  paddingLeft: tableValue?.padding_left ? `${tableValue.padding_left}px` : "0px",
  paddingBottom: tableValue?.padding_bottom ? `${tableValue.padding_bottom}px` : "0px",
})