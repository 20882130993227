import { el } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { Button, ButtonToolbar } from "rsuite";

export function FormulaSaveButton({
  formulaId = "",
  onchnage,
  pracDetails,
  formulaDetail,
  is_order,
  allowSubmitBtn = false,
}) {
  const [online, setOnline] = useState(window.navigator.onLine);
  const [allow_checkout, set_allow_checkout] = useState(false);
  const [review, setReview] = useState(false);
  const [payment_method, set_payment_method] = useState([]);
  const [prac_user_type, set_prac_user_type] = useState("");
  const [dynamicStyles, setDynamicStyles] = useState({});

  useEffect(() => {
    const finalData = formulaDetail?.save_btn
      ? JSON.parse(formulaDetail.save_btn)
      : {};
    const styles = {};
    Object.entries(finalData).forEach(([key, value]) => {
      if (value.is_active) {
        styles[value.display_name] = {
          fontSize: value?.font_size ? `${value.font_size}px` : "12px",
          color: value?.font_color,
          backgroundColor: value?.section_bg_color,
          textDecoration: value?.underline == "1" ? "underline" : "none",
          fontWeight: value?.bold == "1" ? "bold" : "normal",
          marginTop: value?.title_margin_top
            ? `${value.title_margin_top}px`
            : "0px",
          marginRight: value?.title_margin_right
            ? `${value.title_margin_right}px`
            : "0px",
          marginLeft: value?.title_margin_left
            ? `${value.title_margin_left}px`
            : "0px",
          marginBottom: value?.title_margin_bottom
            ? `${value.title_margin_bottom}px`
            : "0px",
          borderColor: value?.border_color,
          borderWidth: value?.border ? `${value.border}px` : "2px",
          borderStyle: value?.border || "2px" ? "solid" : "none",
          position: value.position,
          paddingTop: value?.padding_top ? `${value.padding_top}px` : "0px",
          paddingRight: value?.padding_right ? `${value.padding_right}px` : "0px",
          paddingLeft: value?.padding_left ? `${value.padding_left}px` : "0px",
          paddingBottom: value?.padding_bottom ? `${value.padding_bottom}px` : "0px"
        };
      }
    });
    setDynamicStyles(styles);
  }, [formulaDetail]);

  const getButtonStyle = (displayName) => dynamicStyles[displayName] || {};

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setOnline(window.navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  const updateReview = (value) => {
    setReview(value);
  };

  useEffect(() => {
    if (pracDetails?.PRAC_CHECKOUT_CONTROL_SETTING == 1) {
      set_allow_checkout(true);
    } else {
      set_allow_checkout(false);
    }

    if (pracDetails && pracDetails.payment_method) {
      set_payment_method(pracDetails.payment_method);
    } else {
      set_payment_method([]);
    }
    set_prac_user_type(pracDetails ? pracDetails.prac_user_type : "");
  }, [pracDetails]);

  useEffect(() => {
    // Check if formulaDetail contains the expected value for allow_review
    if (formulaDetail?.allow_review == 1) {
      updateReview(true);
    } else {
      updateReview(false);
    }
  }, [formulaDetail]);

  if (online && is_order === "true") {
    return (
      <div className="row">
        <div
          align="right"
          className="FormulaBuilderSaveButton"
          style={{ marginTop: "3rem", marginBottom: "2rem" }}
        >
          <Button
            title="Save Order"
            appearance="primary"
            disabled={allowSubmitBtn}
            onClick={(e) => {
              onchnage("save_order");
            }}
            style={getButtonStyle("Save Order")}
          >
            Save Order
          </Button>
        </div>
      </div>
    );
  }
  if (online) {
    return (
      <div className="row">
        <div
          align="center"
          className="FormulaBuilderSaveButton"
          style={{ marginTop: "2rem", marginBottom: "0rem" }}
        >
          <ButtonToolbar style={{ float: "right", marginBottom: "0rem" }}>
            {allow_checkout && payment_method.length != 0 && review ? (
              <>
                <Button
                  title="Save Formula and Exit"
                  appearance="primary"
                  disabled={allowSubmitBtn}
                  onClick={() => onchnage("exit")}
                  style={getButtonStyle("Save Formula + Exit")}
                >
                  Save Formula + Exit
                </Button>
                <Button
                  title="Save Formula and Continue Editing"
                  appearance="primary"
                  disabled={allowSubmitBtn}
                  onClick={() => onchnage("continue_editing")}
                  style={getButtonStyle("Save Formula + Continue Editing")}
                >
                  Save Formula + Continue Editing
                </Button>
                <Button
                  title="Save + Review Formula"
                  appearance="primary"
                  disabled={allowSubmitBtn}
                  onClick={() => onchnage("save")}
                  style={getButtonStyle("Save + Review Formula")}
                >
                  Save + Review Formula
                </Button>
              </>
            ) : (
              <>
                {review && (
                  <>
                    {prac_user_type != "student" && (
                      <Button
                        title="Save Formula and Exit"
                        appearance="primary"
                        disabled={allowSubmitBtn}
                        onClick={() => onchnage("exit")}
                        style={getButtonStyle("Save Formula + Exit")}
                      >
                        Save Formula + Exit
                      </Button>
                    )}
                    <Button
                      title="Save Formula and Continue Editing"
                      appearance="primary"
                      disabled={allowSubmitBtn}
                      onClick={() => onchnage("continue_editing")}
                      style={getButtonStyle("Save Formula + Continue Editing")}
                    >
                      Save Formula + Continue Editing
                    </Button>
                    <Button
                      title="Save + Review Formula"
                      appearance="primary"
                      disabled={allowSubmitBtn}
                      onClick={() => onchnage("save")}
                      style={getButtonStyle("Save + Review Formula")}
                    >
                      Save + Review Formula
                    </Button>
                  </>
                )}
              </>
            )}

            {allow_checkout && payment_method.length != 0 && !review ? (
              <>
                {prac_user_type == "student" && (
                  <Button
                    title="Submit to Supervisor"
                    appearance="primary"
                    disabled={allowSubmitBtn}
                    onClick={() => onchnage("exit")}
                    style={getButtonStyle("Submit to Supervisor")}
                  >
                    Submit to Supervisor
                  </Button>
                )}
                <Button
                  title="Save Formula and Exit"
                  appearance="primary"
                  disabled={allowSubmitBtn}
                  onClick={() => onchnage("exit")}
                  style={getButtonStyle("Save Formula + Exit")}
                >
                  Save Formula + Exit
                </Button>
                <Button
                  title="Save Formula and Continue Editing"
                  appearance="primary"
                  disabled={allowSubmitBtn}
                  onClick={() => onchnage("continue_editing")}
                  style={getButtonStyle("Save Formula + Continue Editing")}
                >
                  Save Formula + Continue Editing
                </Button>
                {prac_user_type !== "student" && (
                  <Button
                    title="Save + Add to Cart"
                    appearance="primary"
                    disabled={allowSubmitBtn}
                    onClick={() => onchnage("cart")}
                    style={getButtonStyle("Add to Cart")}
                  >
                    Add to Cart
                  </Button>
                )}
              </>
            ) : (
              <>
                {!review && (
                  <>
                    {prac_user_type == "student" && (
                      <Button
                        title="Submit to Supervisor"
                        appearance="primary"
                        disabled={allowSubmitBtn}
                        onClick={() => onchnage("exit")}
                        style={getButtonStyle("Submit to Supervisor")}
                      >
                        Submit to Supervisor
                      </Button>
                    )}
                    <Button
                      title="Save Formula and Exit"
                      appearance="primary"
                      disabled={allowSubmitBtn}
                      onClick={() => onchnage("exit")}
                      style={getButtonStyle("Save Formula + Exit")}
                    >
                      Save Formula + Exit
                    </Button>
                    <Button
                      title="Save Formula and Continue Editing"
                      appearance="primary"
                      disabled={allowSubmitBtn}
                      onClick={() => onchnage("continue_editing")}
                      style={getButtonStyle("Save Formula + Continue Editing")}
                    >
                      Save Formula + Continue Editing
                    </Button>

                    {prac_user_type !== "student" && (
                      <>
                        <Button
                          title="Submit to Admin"
                          appearance="primary"
                          disabled={allowSubmitBtn}
                          onClick={() => onchnage("order")}
                          style={getButtonStyle("Submit to Admin")}
                        >
                          Submit to Admin
                        </Button>
                        <Button
                          title="Save + Add to Cart"
                          appearance="primary"
                          disabled={allowSubmitBtn}
                          onClick={() => onchnage("cart")}
                          style={getButtonStyle("Add to Cart")}
                        >
                          Add to Cart
                        </Button>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </ButtonToolbar>
        </div>
      </div>
    );
  }

  // if offline
  return (
    <div align="center" className="FormulaBuilderSaveButton">
      <Button
        color="yellow"
        title="You are currently offline now."
        disabled
        appearance="primary"
        type="submit"
        style={{ marginTop: "4rem", marginBottom: "2rem" }}
      >
        ⚠ Offline
      </Button>
    </div>
  );
}
